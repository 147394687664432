/************
 * Author : Sakthipriya
 * Developed by : Shiva Software Solutions
 * Date : 11-05-2023
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  NER_REPORT_ENTITY_RULER,
  NER_SUGGESTION_SUCCESS,
} from '../../shared/constants/ActionTypes';
const initialState = {
  nerReportList: '',
  clientlist: '',
  doctorlist: '',
  reportcount: '',
  allergyreport: '',
  procedurereport: '',
  search_disease_data: [],
  excel_report: [],
  tagcategory: [],
  reportresult: [],
  search_diagonsis_data: [],
  search_symptoms_data: [],
  search_medicine_data: [],
  search_specialization_data: [],
  reportresultsuccess: '',
  erxrhtmldata: '',
  erxrhtmlnodata: '',
  nerSuccess: [],
};

const nerReportentityruler = (state = initialState, action) => {
  switch (action.type) {
    case NER_REPORT_ENTITY_RULER:
      return {
        ...state,
        nerReportList: action.payload,
      };
    case NER_SUGGESTION_SUCCESS:
      return {
        ...state,
        nerSuccess: action.payload,
      };
    default:
      return state;
  }
};
export default nerReportentityruler;
