/************
 * Author : Mangaleswari P
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : Logout Confirmation
 * Dependicies : To use @material-ui/core
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {useAuthUser} from '../../utility/AppHooks';
import {AuthType} from '../../../shared/constants/AppEnums';
import {
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
} from '../../../redux/actions';
import PropTypes from 'prop-types';
// style applied to this page
const useStyles = makeStyles((theme) => ({
  rootdialog: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogwidth: {
    '& .MuiDialog-paperWidthSm': {
      width: '40%',
    },
  },
  btnblue: {
    background: '#2e4167',
    color: '#ffffff',
    '&:hover': {
      background: '#2e4167',
      color: '#ffffff',
    },
  },
  btnred: {
    background: '#ff7252',
    color: '#ffffff',
    '&:hover': {
      background: '#ff7252',
      color: '#ffffff',
    },
  },

  dialogactionsclass: {
    marginBottom: '10px !important',
    marginTop: '10px !important',
  },
}));

const ConfirmationDialog = ({opendialog, onDeny, email, userid}) => {
  const classes = useStyles();
  const user = useAuthUser();
  const dispatch = useDispatch();

  // logout yes click event
  const logoutUser = () => {
    onDeny(false);
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (user && user.authType === AuthType.AWS_COGNITO) {
      dispatch(onCognitoUserSignOut());
    } else if (user && user.authType === AuthType.FIREBASE) {
      dispatch(onSignOutFirebaseUser());
    } else if (user && user.authType === AuthType.AUTH0) {
      dispatch(onSignOutAuth0User());
    } else if (user && user.authType === AuthType.JWT_AUTH) {
      // signout redux api calling
      let pushdata = {
        userId: user.userId,
        userCode: user.userCode,
        onlineStatus: 'Logout',
        sessionID: user.sessionID,
      };
      dispatch(onJWTAuthSignout(pushdata));
    }
  };

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  //Delete dialog
  const DialogTitle = withStyles(useStyles)((props) => {
    const {children, classes} = props;
    return (
      <MuiDialogTitle disableTypography className={classes.rootdialog}>
        <Typography variant='h6'>{children}</Typography>
      </MuiDialogTitle>
    );
  });

  return (
    <>
      <Dialog
        className={classes.dialogwidth}
        open={opendialog}
        onClose={() => onDeny(false)}
        aria-labelledby='customized-dialog-title'>
        <DialogTitle id='customized-dialog-title'>Confirmation</DialogTitle>
        <DialogContent>
          <Typography
            gutterBottom
            component='h4'
            style={{fontWeight: 600, paddingLeft: 16, color: '#000'}}>
            Are you sure you want to Logout ?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogactionsclass}>
          <Button onClick={() => onDeny(false)} className={classes.btnred}>
            No
          </Button>
          <Button onClick={logoutUser} className={classes.btnblue}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ConfirmationDialog;
ConfirmationDialog.propTypes = {
  opendialog: PropTypes.bool.isRequired,
  onDeny: PropTypes.func.isRequired,
};
