/************
 * Developed by : Shiva Software Solutions
 * Date : 27-12-2023
 * Descriptions : Route for eRx Details
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';
//Route config for eRx details
export const digitizationeRxDetailsConfigs = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/digitizationeRxDetails',
        component: React.lazy(() => import('./eRxDetailsPage')),
      },
    ],
  },
];
