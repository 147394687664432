/************
 * Developed by : Shiva Software Solutions
 * Date : 22-03-23
 * Descriptions : Gudmed New vendor Dialog Box
 * Dependicies : To use @material-ui/core
 ************/

import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ToasterMsg from '../../ToasterMsg';
import IntlMessages from '../../../@crema/utility/IntlMessages';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {grey} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
// for password textfield
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// for radio button status
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
  AvoidSpecialCharacterRegExp,
  AvoidSpecialCharacterMailRegExp,
  prescription_Type,
} from '../../../shared/constants/AppConst';

// import Snackbar from '@material-ui/core/Snackbar';
import {
  GET_VENDORUPDATE_DATA,
  GET_VENDOR_DATA,
  NEW_VENDOR_NOTIFI,
} from '../../../shared/constants/ActionTypes';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Addvendor,
  onWriteCloudWatchLog,
  Updatevendor,
} from '../../../redux/actions';

// import constants values
import {
  MailRegExp,
  passwordRegExp,
  AgentIdRegExp,
  regEx,
} from '../../../shared/constants/AppConst';

const NewVendor = ({title, open, onDeny, data, hidedata}) => {
  const useStyle = makeStyles({
    formControl: {
      margin: 1,
      minWidth: 120,
    },
    dialogStyle: {
      fontStyle: Fonts.FONTSTYLE,
      position: 'relative',
      padding: 4,
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 800,
        width: '100%',
      },
      '& .MuiDialog-paperWidthSm .MuiBox-root:first-child': {
        padding: 0,
      },
    },
    dialogHeader: {
      backgroundColor: '#2e4167',
      padding: 0,
      alignItems: 'center',
      borderBottom: ['1px solid #afa2a2a6'],
    },
    dialogheadStyle: {
      padding: 5,
      color: '#fff',
    },
    dialogTitle: {
      fontWeight: 'bold',
      marginBottom: 0,
      fontSize: Fonts.TableHeader,
      fontStyle: Fonts.FONTSTYLE,
    },
    textFieldAgent: {
      fontStyle: Fonts.FONTSTYLE,
      fontSize: 10,
      width: '99%',
      '& .MuiFormLabel-root': {
        fontSize: 14,
      },
      '& .MuiInputLabel-asterisk': {
        color: 'red!important',
      },
      '& .MuiInputBase-input': {
        textTransform: 'uppercase',
      },
      marginTop: 0,
      marginBottom: 0,
      padding: 2,
      marginLeft: 1,
      marginRight: 1,
    },

    textFieldName: {
      fontStyle: Fonts.FONTSTYLE,
      fontSize: 10,
      width: '99%',
      '& .MuiFormLabel-root': {
        fontSize: 14,
      },
      '& .MuiInputLabel-asterisk': {
        color: 'red!important',
      },
      '& .MuiInputBase-input': {
        textTransform: 'capitalize',
      },
      marginTop: 0,
      marginBottom: 0,
      padding: 2,
      marginLeft: 1,
      marginRight: 1,
    },
    textField: {
      fontStyle: Fonts.FONTSTYLE,
      fontSize: 10,
      width: '99%',
      '& .MuiFormLabel-root': {
        fontSize: 14,
      },
      '& .MuiInputLabel-asterisk': {
        color: 'red!important',
      },

      marginTop: 0,
      marginBottom: 0,
      padding: 2,
      marginLeft: 1,
      marginRight: 1,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    btnStyles: {
      textAlign: 'right',
      padding: 10,
    },
    closeBtn: {
      padding: 5,
      textAlign: 'right',
    },
    closeBtnColor: {
      color: '#fff',
    },
    btn: {
      marginLeft: 8,
      color: '#000',
      fontWeight: Fonts.MEDIUM,
    },
    savebtn: {
      backgroundColor: '#2e4167',
      color: '#fff',
      marginLeft: 8,
      fontWeight: Fonts.MEDIUM,
      '&:hover': {
        backgroundColor: '#2e4167',
        color: '#ffffff',
      },
    },
    contentText: {
      color: grey[600],
      padding: 15,
      '& .MuiGrid-item': {
        marginTop: 2,
      },
    },
    statusStyle: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
    },
    statusBox: {
      marginTop: 6,
      alignItems: 'center',
      '& .MuiFormLabel-root': {
        fontSize: 15,
      },
    },
    magTop: {
      marginTop: '10% !important',
    },
  });

  // use sthe styles for ui
  const classes = useStyle();
  // dispatch used to calling the redux apis and store the values to redux variables
  const dispatch = useDispatch();
  //AWS cloud watch name
  var streamname = 'vendorManage';
  var filename = 'newVendor.js';
  // selector used to get the redux variables
  // get the login user details
  const LoginUser = useSelector(({auth}) => auth);
  const [msgdata, setmsgdata] = useState('');
  const [variant, setvariant] = useState('error');
  const [snackbaropen, setsnackbaropen] = useState(false);
  const notifiList = useSelector(({vendorData}) => vendorData.newVendor);
  const [disable, setDisable] = React.useState(false);
  const vendorList = useSelector(({vendorData}) => vendorData.vendorList);
  const errorList = useSelector(({vendorData}) => vendorData.vendorupdate);
  //Editable time get the data and bind ui
  const editloaddata = useSelector(({userManage}) => userManage.editloaddata);
  // state value declaration
  const [vendorName, setVendorName] = React.useState(null);
  const [prescriptionType, setprescriptionType] = React.useState(null);

  //state variable for new user data
  const [values, setValues] = React.useState({
    username: '',
    emailid: '',
    password: '',
    status: 'Inactive',
    showPassword: false,
    credentialId: '',
  });
  const [errorValues, setErrorValues] = React.useState({
    email: false,
    pwd: false,
    username: false,
    vendorname: false,
    prescriptionType: false,
  });
  useEffect(() => {
    if (data && data.length > 0 && data[1]) {
      vendorList.map((item) => {
        if (item.clientName == data[1]) {
          setVendorName(item);
          return;
        }
      });
    }
    let type = data && data?.length > 0 ? data[5] : 0;
    let json = prescription_Type.map((item) => {
      if (item?.Id == Number(type)) {
        setprescriptionType(item);
        return;
      }
    });
    setValues({
      ...values,
      username: data && data?.length > 0 ? data[3] : '',
      emailid: data && data?.length > 0 ? data[4] : '',
      status: data && data?.length > 0 ? data[6] : 2,
      credentialId: data && data?.length > 0 ? data[2] : '',
    });
  }, [data]);
  // password show and hide functionality
  const handleClickShowPassword = () => {
    setValues({...values, showPassword: !values.showPassword});
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function containsSpecialChars(str) {
    return AvoidSpecialCharacterRegExp.test(str);
  }
  function containsSpecialCharsemail(str) {
    return AvoidSpecialCharacterMailRegExp.test(str);
  }

  // status change event
  const statusChangeEvent = (event) => {
    setValues({
      ...values,
      status: parseInt(event.target.value),
    });
  };
  useEffect(() => {
    if (notifiList && notifiList != '') {
      setvariant('success');
      setmsgdata(notifiList);
      setsnackbaropen(true);
      dispatch({
        type: NEW_VENDOR_NOTIFI,
        payload: '',
      });
    }
  }, [notifiList]);
  // clear the data for state values
  const clearData = () => {
    setVendorName('');
    setprescriptionType(null);
    setValues({
      ...values,
      username: '',
      emailid: '',
      password: '',
      status: 2,
      showPassword: false,
      credentialId: '',
    });
    setErrorValues({
      ...errorValues,
      email: false,
      pwd: false,
      username: false,
      vendorname: false,
    });
  };

  //fields enter click event
  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      if (index === 10) {
        index = 11;
      }
      if (index === 16) {
        index = 18;
      }
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  // save /update user details
  const saveVendorCredential = async () => {
    try {
      if (!vendorName) {
        setErrorValues({
          ...errorValues,
          vendorname: true,
        });
      }
      //Email validation
      else if (
        values.emailid.trim() === '' ||
        !MailRegExp.test(values.emailid)
      ) {
        setErrorValues({...errorValues, email: true});
        return;
      }

      //user name validation
      else if (!values.username) {
        setErrorValues({...errorValues, username: true});
        return;
      } else if (
        values.username === '' ||
        AvoidSpecialCharacterMailRegExp.test(values.username)
      ) {
        setErrorValues({...errorValues, username: true});
        return;
      } else if (!prescriptionType?.type) {
        setErrorValues({...errorValues, prescriptionType: true});
        return;
      }
      //pwd validation
      else if (
        hidedata === false &&
        Object.keys(editloaddata).length === 0 &&
        (values.password.trim() === '' || !passwordRegExp.test(values.password))
      ) {
        setErrorValues({...errorValues, pwd: true});
        return;
      } else if (values) {
        setErrorValues({
          ...errorValues,
          email: false,
          pwd: false,
          username: false,
        });
        setDisable(true);
        // call redux action for api calling
        if (hidedata === false) {
          var passdata = {};
          passdata = {
            clientId: vendorName.clientID,
            userId: values.username,
            userPaswd: values.password,
            emailId: values.emailid,
            userCode: LoginUser.user.userCode,
            prescriptionType: prescriptionType?.Id ? prescriptionType?.Id : 0,
            userCategory: 'User',
          };
          dispatch(Addvendor(passdata));
          setDisable(false);
        }
        if (hidedata === true) {
          var passdata = {};
          passdata = {
            credentialId: values.credentialId,
            clientId: vendorName.clientID?.toString(),
            userId: values.username,
            statusCode: values.status,
            emailId: values.emailid,
            userCode: LoginUser.user.userCode,
            prescriptionType: prescriptionType?.Id ? prescriptionType?.Id : 0,
            userCategory: 'User',
          };
          dispatch(Updatevendor(passdata));
          setDisable(false);
        }
      }
    } catch (err) {
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'newvendoruser',
          LoginUser && LoginUser.user ? LoginUser.user.userCode : '0',
          err,
        ),
      );
    }
  };
  useEffect(() => {
    if (errorList && errorList == 'true') {
      onDeny(false);
      clearData();
      dispatch({
        type: GET_VENDORUPDATE_DATA,
        payload: '',
      });
    } else if (errorList == 'false') {
      onDeny(true);
      dispatch({
        type: GET_VENDORUPDATE_DATA,
        payload: '',
      });
    }
  }, [errorList]);

  const closePopuptab = () => {
    setDisable(false);
    onDeny(false);
    clearData();
  };
  return (
    <>
      <ToasterMsg
        snackbaropen={snackbaropen}
        variantdata={variant}
        msgdata={msgdata}
        onDeny={setsnackbaropen}
      />
      <Dialog open={open} className={classes.dialogStyle} disableBackdropClick>
        <Grid container className={classes.dialogHeader}>
          <Grid item={true} xs={12} md={6} className={classes.dialogheadStyle}>
            <Box
              component='h4'
              fontWeight={Fonts.REGULAR}
              className={classes.dialogTitle}
              id='userDialogTitle'>
              {title}
            </Box>
          </Grid>
          <Grid item={true} xs={12} md={6} className={classes.closeBtn}>
            <IconButton onClick={() => closePopuptab()}>
              <HighlightOffIcon className={classes.closeBtnColor} />
            </IconButton>
          </Grid>
        </Grid>

        <DialogContent className={classes.contentText} id='dialogDescription'>
          <form>
            <Grid container spacing={6}>
              <Grid item={true} xs={12} md={12}>
                <Grid container spacing={6}>
                  <Grid item={true} xs={12} md={6} autoFocus>
                    <Autocomplete
                      options={vendorList ? vendorList : []}
                      getOptionLabel={(option) =>
                        option && option.clientName ? option.clientName : ''
                      }
                      autoFocus
                      id=''
                      fullWidth
                      value={vendorName}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        setVendorName(value);
                        setErrorValues({
                          ...errorValues,
                          vendorname: false,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label='Select Client'
                          className={classes.textField}
                          onKeyPress={(event) => {
                            if (containsSpecialChars(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            var res = '';
                            if (event.clipboardData.getData('text')) {
                              res = event.clipboardData.getData('text');
                            } else {
                              res = event.clipboardData.getData('text/html');
                            }
                            if (containsSpecialChars(res)) {
                              event.preventDefault();
                            }
                          }}
                          autoFocus
                          autoComplete='off'
                          error={errorValues.vendorname === true}
                          helperText={
                            errorValues.vendorname === true
                              ? 'Please select client'
                              : ' '
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4}>
                    <TextField
                      required
                      id='emailId'
                      value={values.emailid}
                      inputProps={{maxLength: 150}}
                      name='emailId'
                      onKeyDown={handleEnter}
                      className={classes.textField}
                      type='email'
                      onBlur={(event) => {
                        //  mail id validation
                        if (event.target.value !== '') {
                          if (!MailRegExp.test(event.target.value)) {
                            setErrorValues({...errorValues, email: true});
                          } else {
                            setErrorValues({...errorValues, email: false});
                          }
                        }
                      }}
                      onKeyPress={(event) => {
                        if (containsSpecialCharsemail(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        var res = '';
                        if (event.clipboardData.getData('text')) {
                          res = event.clipboardData.getData('text');
                        } else {
                          res = event.clipboardData.getData('text/html');
                        }
                        if (containsSpecialCharsemail(res)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        setValues({
                          ...values,
                          emailid: event.target.value,
                        });
                        setErrorValues({...errorValues, email: false});
                      }}
                      autoComplete='off'
                      error={errorValues.email === true}
                      helperText={
                        errorValues.email === true
                          ? 'Please enter valid Email'
                          : ' '
                      }
                      label='Email ID'
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={2}>
                    <TextField
                      required
                      value={values.username}
                      id='username'
                      ref={(element) => element?.focus?.()}
                      onKeyDown={handleEnter}
                      inputProps={{maxLength: 50}}
                      className={classes.textField}
                      onChange={(event) => {
                        if (event.target.value.trim() === '') {
                          setValues({
                            ...values,
                            username: event.target.value,
                          });
                        }
                        if (
                          !AvoidSpecialCharacterMailRegExp.test(
                            event.target.value,
                          )
                        ) {
                          setErrorValues({...errorValues, username: false});
                          setValues({
                            ...values,
                            username: event.target.value,
                          });
                        } else {
                          setErrorValues({
                            ...errorValues,
                            username: true,
                          });
                        }
                      }}
                      onKeyPress={(event) => {
                        if (containsSpecialCharsemail(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(event) => {
                        var res = '';
                        if (event.clipboardData.getData('text')) {
                          res = event.clipboardData.getData('text');
                        } else {
                          res = event.clipboardData.getData('text/html');
                        }
                        if (containsSpecialCharsemail(res)) {
                          event.preventDefault();
                        }
                      }}
                      name='username'
                      label='User ID'
                      autoComplete='off'
                      error={errorValues.username === true}
                      helperText={
                        errorValues.username === true
                          ? 'Please use alphabet and numbers'
                          : ' '
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item={true} xs={12} md={6}>
                    <Autocomplete
                      options={prescription_Type ? prescription_Type : []}
                      getOptionLabel={(option) =>
                        option && option.type ? option.type : ''
                      }
                      id=''
                      fullWidth
                      value={prescriptionType}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        setprescriptionType(value);
                        setErrorValues({
                          ...errorValues,
                          prescriptionType: false,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label='Select Prescription Type'
                          className={classes.textField}
                          onKeyPress={(event) => {
                            if (containsSpecialChars(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onPaste={(event) => {
                            var res = '';
                            if (event.clipboardData.getData('text')) {
                              res = event.clipboardData.getData('text');
                            } else {
                              res = event.clipboardData.getData('text/html');
                            }
                            if (containsSpecialChars(res)) {
                              event.preventDefault();
                            }
                          }}
                          autoComplete='off'
                          error={errorValues.prescriptionType === true}
                          helperText={
                            errorValues.prescriptionType === true
                              ? 'Please select prescriptionType'
                              : ' '
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={6}>
                    {hidedata === false ? (
                      <TextField
                        required
                        id='password'
                        value={values.password}
                        className={classes.textField}
                        onChange={(event) => {
                          setValues({...values, password: event.target.value});
                          setErrorValues({...errorValues, pwd: false});
                        }}
                        name='password'
                        label='Password'
                        autoComplete='off'
                        onKeyDown={handleEnter}
                        onBlur={(event) => {
                          // password validation
                          if (!passwordRegExp.test(event.target.value)) {
                            setErrorValues({...errorValues, pwd: true});
                          } else {
                            setErrorValues({...errorValues, pwd: false});
                          }
                        }}
                        type={values.showPassword ? 'text' : 'password'}
                        InputProps={{
                          minLength: 8,
                          maxLength: 20,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                aria-label='toggle password visibility'
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}>
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        helperText={
                          errorValues.pwd === true
                            ? 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
                            : ' '
                        }
                        error={errorValues.pwd === true}
                      />
                    ) : (
                      <Grid container className={classes.statusBox}>
                        <Grid item={true} xs={12} md={3}>
                          <FormLabel component='legend'>Status</FormLabel>
                        </Grid>
                        <Grid item={true} xs={12} md={9}>
                          <RadioGroup
                            aria-label='status'
                            name='status'
                            value={values.status}
                            onKeyDown={handleEnter}
                            onChange={statusChangeEvent}
                            className={classes.statusStyle}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              label='Active'
                            />
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              label='Inactive'
                            />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box pt={2} className={classes.btnStyles}>
              <Button
                disabled={disable}
                className={classes.savebtn}
                variant='outlined'
                onClick={() => saveVendorCredential()}>
                {}
                {hidedata === true ? <>Update</> : <>save</>}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewVendor;

NewVendor.propTypes = {
  title: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  error: PropTypes.node.isRequired,
  onDeny: PropTypes.func.isRequired,
  hidedata: PropTypes.bool.isRequired,
};
