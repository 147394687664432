/************
 * Developed by : Shiva Software Solutions
 * Date : 10-01-2022
 * Descriptions : Call api response using reducers for  covid vitals data
 ************/
import {
  COVID_VITAL_LIST,
  COVID_VITAL_LIST_COUNT,
  VITAL_DATA_NOT_FOUND,
  VITAL_PDF_DATA,
  PATIENT_VITAL_PATIENTS,
  COVID_DATA_HTML,
  COVID_DATA_HTML_NO_DATA,
  PATIENT_VITAL_HISTORY_DATA,
  PATIENT_VITAL_HISTORY_DATA_COUNT,
} from '../../shared/constants/ActionTypes';
//Inital state
const initialState = {
  patientvitallist: [],
  patientvitalcount: 0,
  vital_list: [],
  vital_count: 0,
  errordata: '',
  vitalsdata: '',
  vitalpatients: [],
  covid_datas: [],
  covid_no_datas: '',
};

const covidVitalDataReducer = (state = initialState, action) => {
  switch (action.type) {
    //symptom master list
    case COVID_VITAL_LIST:
      return {
        ...state,
        vital_list: action.payload,
      };
    case COVID_VITAL_LIST_COUNT:
      return {
        ...state,
        vital_count: action.payload,
      };
    //Get vital history
    case VITAL_PDF_DATA:
      return {
        ...state,
        vitalsdata: action.payload,
      };
    //Get vital error
    case VITAL_DATA_NOT_FOUND:
      return {
        ...state,
        errordata: action.payload,
      };
    case PATIENT_VITAL_HISTORY_DATA:
      return {
        ...state,
        patientvitallist: action.payload,
      };
    case PATIENT_VITAL_HISTORY_DATA_COUNT:
      return {
        ...state,
        patientvitalcount: action.payload,
      };
    case PATIENT_VITAL_PATIENTS:
      return {
        ...state,
        vitalpatients: action.payload,
      };
    //GEt covid data
    case COVID_DATA_HTML:
      return {
        ...state,
        covid_datas: action.payload,
      };
    //GEt covid error
    case COVID_DATA_HTML_NO_DATA:
      return {
        ...state,
        covid_no_datas: action.payload,
      };

    default:
      return state;
  }
};
export default covidVitalDataReducer;
