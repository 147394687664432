/************
 * Developed by : Shiva Software Solutions
 * File Name : Patient.js
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA,
  PATIENT_COMPLETED_COUNT,
  PATIENT_NAME_LIST,
  PATIENT_COMPLETED_DATA,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  PICK_PATIENT_MOBILE_NO,
  PATIENT_LISTS,
  SEARCH_REPORTS_DATA,
  SEARCH_REPORTS_DATA_COUNT,
  INVESTIGATIONS_LIST,
  REPORT_PATIENT_LIST,
  INDIVIDUAL_PATIENT_APPOINTMENT_LIST,
  INDIVIDUAL_PATIENT_APPOINTMENT_COUNT,
} from '../../shared/constants/ActionTypes';

const initialState = {
  patientlist: null,
  erxhtmldata: [],
  erxhtmlnodata: '',
  patNameList: [],
  patientcount: null,
  erximgdatas: [],
  erximgocrdatas: [],
  pick_patient_mobileno: '',
  patientListdata: [],
  searchReportsData: [],
  searchReportsDataCounts: 0,
  inves_list: [],
  patient_list: [],
  patient_AppointmentCount: 0,
  patient_Appointments: [],
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATIENT_COMPLETED_DATA:
      return {
        ...state,
        patientlist: action.payload,
      };
    case ERX_DATA_HTML:
      return {
        ...state,
        erxhtmldata: action.payload,
      };
    case ERX_DATA_HTML_NO_DATA:
      return {
        ...state,
        erxhtmlnodata: action.payload,
      };
    case PATIENT_NAME_LIST:
      return {
        ...state,
        patNameList: action.payload,
      };
    case PATIENT_COMPLETED_COUNT:
      return {
        ...state,
        patientcount: action.payload,
      };
    case ERX_IMG_ARR:
      return {
        ...state,
        erximgdatas: action.payload,
      };
    //get erx with img data
    case ERX_IMG_OCR:
      return {
        ...state,
        erximgocrdatas: action.payload,
      };
    case PICK_PATIENT_MOBILE_NO:
      return {
        ...state,
        pick_patient_mobileno: action.payload,
      };
    case PATIENT_LISTS:
      return {
        ...state,
        patientListdata: action.payload,
      };
    case SEARCH_REPORTS_DATA:
      return {
        ...state,
        searchReportsData: action.payload,
      };

    case SEARCH_REPORTS_DATA_COUNT:
      return {
        ...state,
        searchReportsDataCounts: action.payload,
      };
    case INVESTIGATIONS_LIST:
      return {
        ...state,
        inves_list: action.payload,
      };
    case REPORT_PATIENT_LIST:
      return {
        ...state,
        patient_list: action.payload,
      };
    case INDIVIDUAL_PATIENT_APPOINTMENT_LIST:
      return {
        ...state,
        patient_Appointments: action.payload,
      };
    case INDIVIDUAL_PATIENT_APPOINTMENT_COUNT:
      return {
        ...state,
        patient_AppointmentCount: action.payload,
      };
    default:
      return state;
  }
};
export default patientReducer;
