import React, {useContext, useEffect} from 'react';
import AppHeader from './AppHeader';
import {ContentView} from '../../../index';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import clsx from 'clsx';
import AppFooter from './AppFooter';
import AppFixedFooter from './AppFixedFooter';
import AppContext from '../../../utility/AppContext';
import AppSidebar from './AppSidebar';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import {useDispatch, useSelector} from 'react-redux';
import {onGetAdminPendingPresCount} from '../../../../redux/actions';
import jwtAxios from '../../../services/auth/jwt-auth/jwt-api';
import {FETCH_ERROR} from '../../../../shared/constants/ActionTypes';

const HorDefault = (props) => {
  const {footer, layoutType, footerType} = useContext(AppContext);
  const classes = useStyles(props);
  const LoginUser = useSelector(({auth}) => auth);
  const dispatch = useDispatch();
  setInterval(updateScroll, 180000);
  function updateScroll() {
    if (LoginUser?.user?.userCode && Number(LoginUser?.user?.roleId) == 1) {
      dispatch(
        onGetAdminPendingPresCount({
          userCode: LoginUser?.user?.userCode,
          userId: LoginUser?.user?.userId,
        }),
      );
    }
  }

  useEffect(() => {
    if (
      Number(LoginUser?.user?.roleId) == 4 &&
      Number(LoginUser?.user?.userCategory) == 1
    ) {
      setInterval(activeLogL3, 60000);
    }
  }, []);

  async function activeLogL3() {
    if (
      Number(LoginUser?.user?.roleId) == 4 &&
      Number(LoginUser?.user?.userCategory) == 1
    ) {
      var body = {
        userCode: LoginUser?.user?.userCode,
        userId: LoginUser?.user?.userId,
        sessionID: LoginUser?.user?.sessionID,
      };
      await jwtAxios
        .post('v1/userMgmt/activeLogL3', body)
        .then((res) => {
          console.log('res');
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error});
        });
    }
    return '';
  }
  return (
    <Box
      className={clsx(
        classes.appMain,
        'appMainHor',
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>
      <AppHeader />
      <Box className={classes.mainContent}>
        <AppSidebar />
        <Box className={classes.mainContainer}>
          <ContentView />
        </Box>
      </Box>

      <AppFooter />
      <AppFixedFooter />
    </Box>
  );
};

export default HorDefault;
