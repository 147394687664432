/************
 * Developed by : Shiva Software Solutions
 * Date : 07-08-2021
 * Descriptions : User Mangament api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  CLIENT_HOSPITAL_DET,
  GET_DOCTORMASTER_DATA,
  UPDATE_DOCTORMASTER_DATA,
  GET_PENDING_DOCTORMASTER_DATA,
  GET_INACTIVE_DOCTORMASTER_DATA,
  GET_PRESCRIPTION_DOCTORMASTER,
  MATCHED_APPROVED_DOCTORLIST,
  MATCHED_FETCH_SUCCESS,
  DOCTOR_CATEGORY,
  SET_DOCTOR_CATEGORY,
  APPROVED_DOCTOR_CATEGORY,
  CLIENT_MASTER_DATA,
  MATCHED_APPROVED_DOCTORLIST_VIEW,
  EDIT_DOCTOR_VIEW,
  PENDING_HOSPITAL_DET,
  PENDING_CLIENT_HOSPITAL_DET,
  EDIT_HOSPITAL_DET,
  MATCHED_FETCH_SUCCESS_VIEW,
  EDIT_DOCTOR,
  BOT_QUESTIONS,
  REMINDER_QUESTIONS,
  PEND_BOT_QUESTIONS,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'doctorMaster.js';
//get doctor master list using jwt token
export const onGetDoctorMaster = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/getDoctorsjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/getDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: APPROVED_DOCTOR_CATEGORY,
                      payload: data.data.body.doctorCategory,
                    });
                    dispatch({
                      type: CLIENT_MASTER_DATA,
                      payload: data.data.body.client_master,
                    });
                    if (body.status === '7') {
                      dispatch({
                        type: GET_DOCTORMASTER_DATA,
                        payload: data.data.body.result,
                      });
                    } else if (body.status === '6') {
                      dispatch({
                        type: GET_PENDING_DOCTORMASTER_DATA,
                        payload: data.data.body.result,
                      });
                    } else {
                      dispatch({
                        type: GET_INACTIVE_DOCTORMASTER_DATA,
                        payload: data.data.body.result,
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetDoctorMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDoctorMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};

//get doctor prescription master list using jwt token
export const onGetPrescriptionDoctorMaster = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/getPrescriptionDoctorsjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/getPrescriptionDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_PRESCRIPTION_DOCTORMASTER,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPrescriptionDoctorMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPrescriptionDoctorMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPrescriptionDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPrescriptionDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};

//update doctor details
export const onUpdateDoctorMaster = (body, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/editDoctorjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/editDoctor', body)
                .then((data) => {
                  if (data.status === 200) {
                    let message = data.data.message;
                    if (message.includes('Error')) {
                      dispatch({
                        type: UPDATE_DOCTORMASTER_DATA,
                        payload: 'Failure',
                      });
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch(
                        onGetDoctorMaster({
                          userId: '',
                          userCode: body.userCode,
                          status: '0',
                        }),
                      );
                      dispatch({
                        type: UPDATE_DOCTORMASTER_DATA,
                        payload: 'success',
                      });
                      history.push('/registered-doctors');
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onUpdateDoctorMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onUpdateDoctorMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};

// doctor tab seacrh doctor using mobile No/register
export const onGetmatchedDoctors = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      // get jwt token based on input

      jwtAxios
        .post('v1/doctorMasters/getMatchedDoctorsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorMasters/getMatchedDoctors', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: MATCHED_APPROVED_DOCTORLIST,
                  payload: data.data.body.doctorList,
                });
                dispatch({
                  type: DOCTOR_CATEGORY,
                  payload: data.data.body.category,
                });
                dispatch({
                  type: EDIT_DOCTOR,
                  payload: data.data.body.doctorInfo,
                });
                dispatch({
                  type: PEND_BOT_QUESTIONS,
                  payload: data.data.body.questions,
                });
                dispatch({
                  type: REMINDER_QUESTIONS,
                  payload: data.data.body.reminderQuestions,
                });
                dispatch({
                  type: PENDING_HOSPITAL_DET,
                  payload: data.data.body.Hospital,
                });
                dispatch({
                  type: PENDING_CLIENT_HOSPITAL_DET,
                  payload: data.data.body.clientHospital,
                });
                dispatch({
                  type: MATCHED_FETCH_SUCCESS,
                  payload: 'success',
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getMatchedDoctors',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getMatchedDoctors',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getMatchedDoctorsjwt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getMatchedDoctorsjwt',
          '0',
          err,
        ),
      );
    }
  };
};
//Get Matched doctors
export const onGetmatchedApprovedDoctors = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/doctorMasters/getMatchedDoctorsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorMasters/getmatchedApprovedDoctors', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: MATCHED_APPROVED_DOCTORLIST_VIEW,
                  payload: data.data.body.doctorList,
                });
                dispatch({
                  type: APPROVED_DOCTOR_CATEGORY,
                  payload: data.data.body.category,
                });
                dispatch({
                  type: SET_DOCTOR_CATEGORY,
                  payload: data.data.body.setcategory,
                });
                dispatch({
                  type: EDIT_DOCTOR_VIEW,
                  payload: data.data.body.doctorInfo,
                });
                dispatch({
                  type: BOT_QUESTIONS,
                  payload: data.data.body.questions,
                });
                dispatch({
                  type: REMINDER_QUESTIONS,
                  payload: data.data.body.reminderQuestions,
                });
                dispatch({
                  type: EDIT_HOSPITAL_DET,
                  payload: data.data.body.Hospital,
                });
                dispatch({
                  type: CLIENT_HOSPITAL_DET,
                  payload: data.data.body.clientHospital,
                });
                dispatch({
                  type: MATCHED_FETCH_SUCCESS_VIEW,
                  payload: 'success',
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getmatchedApprovedDoctors',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err?.response?.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'getmatchedApprovedDoctors',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getmatchedApprovedDoctors',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getmatchedApprovedDoctors',
          '0',
          err,
        ),
      );
    }
  };
};
