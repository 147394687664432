/************
 * Developed by : Shiva Software Solutions
 * Date : 15-05-2024
 * Descriptions : IPD reducers
 ************/
import {
  IPD_ADD_MEDICINE,
  APPROVE_IPDMEDICINE_MSG,
  IPD_DEPT_DET,
} from '../../shared/constants/ActionTypes';

const initialState = {
  ipdAddMedicine: null,
  approveIPDMed: null,
  ipdDeptDetails: '',
};

const ipdReducer = (state = initialState, action) => {
  switch (action.type) {
    case IPD_ADD_MEDICINE:
      return {
        ...state,
        ipdAddMedicine: action.payload,
      };
    case APPROVE_IPDMEDICINE_MSG:
      return {
        ...state,
        approveIPDMed: action.payload,
      };
    case IPD_DEPT_DET:
      return {
        ...state,
        ipdDeptDetails: action.payload,
      };
    default:
      return state;
  }
};

export default ipdReducer;
