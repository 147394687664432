/************
 * Author : Sakthipriya
 * Developed by : Shiva Software Solutions
 * Date : 11-05-2023
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  CLIENT_LIST_REPORT,
  REJECT_REASON_REPORT,
  DOCTOR_REPORT,
  PATIENT_REPORT,
  SEARCH_DISEASE_DATA,
  REPORT_RESULT,
  REPORT_RESULT_SUCCESS,
  REPORT_COUNT,
  EXCEL_REPORT_LIST,
  PROCEDURE_REPORT,
  ALLERGY_REPORT,
  TAG_CATEGORY_REPORT,
  SEARCH_DIAGONSIS_DATA,
  ERX_DATA_RHTML_NO_DATA,
  SEARCH_SPECIALIZATION_DATA,
  SEARCH_SYMPTOMS_DATA,
  SEARCH_MEDICINE_DATA,
  ERX_DATA_RHTML,
} from '../../shared/constants/ActionTypes';
const initialState = {
  clientlist: '',
  rejectlist: '',
  doctorlist: '',
  patientlist: '',
  reportcount: '',
  allergyreport: '',
  procedurereport: '',
  search_disease_data: [],
  excel_report: [],
  tagcategory: [],
  reportresult: [],
  search_diagonsis_data: [],
  search_symptoms_data: [],
  search_medicine_data: [],
  search_specialization_data: [],
  reportresultsuccess: '',
  erxrhtmldata: '',
  erxrhtmlnodata: '',
};

const amazonreportReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_LIST_REPORT:
      return {
        ...state,
        clientlist: action.payload,
      };
    case REJECT_REASON_REPORT:
      return {
        ...state,
        rejectlist: action.payload,
      };
    case REPORT_RESULT:
      return {
        ...state,
        reportresult: action.payload,
      };
    case REPORT_RESULT_SUCCESS:
      return {
        ...state,
        reportresultsuccess: action.payload,
      };

    case REPORT_COUNT:
      return {
        ...state,
        reportcount: action.payload,
      };
    case EXCEL_REPORT_LIST:
      return {
        ...state,
        excel_report: action.payload,
      };
    case ALLERGY_REPORT:
      return {
        ...state,
        allergyreport: action.payload,
      };
    case PROCEDURE_REPORT:
      return {
        ...state,
        procedurereport: action.payload,
      };
    case SEARCH_MEDICINE_DATA:
      return {
        ...state,
        search_medicine_data: action.payload,
      };
    case TAG_CATEGORY_REPORT:
      return {
        ...state,
        tagcategory: action.payload,
      };
    case DOCTOR_REPORT:
      return {
        ...state,
        doctorlist: action.payload,
      };
    case PATIENT_REPORT:
      return {
        ...state,
        patientlist: action.payload,
      };
    case SEARCH_DISEASE_DATA:
      return {
        ...state,
        search_disease_data: action.payload,
      };
    case SEARCH_DIAGONSIS_DATA:
      return {
        ...state,
        search_diagonsis_data: action.payload,
      };
    case ERX_DATA_RHTML:
      return {
        ...state,
        erxrhtmldata: action.payload,
      };
    case ERX_DATA_RHTML_NO_DATA:
      return {
        ...state,
        erxrhtmlnodata: action.payload,
      };
    case SEARCH_SYMPTOMS_DATA:
      return {
        ...state,
        search_symptoms_data: action.payload,
      };
    case SEARCH_SPECIALIZATION_DATA:
      return {
        ...state,
        search_specialization_data: action.payload,
      };

    default:
      return state;
  }
};
export default amazonreportReducer;
