/************
 * Developed by : Shiva Software Solutions
 * Date : 15-10-2022
 * Descriptions : Route page for signup
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const abhaRouteConfig = [
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/abhaUsers',
        component: React.lazy(() => import('./abhaUsers')),
      },
    ],
  },
];
