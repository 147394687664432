/************
 * Developed by : Shiva Software Solutions
 * Date : 21-12-2021
 * Descriptions : Call api response using reducers for  master data
 ************/
import {
  GET_SYMPTOM_MASTER_DATA,
  SYMPTOM_SUCCESS,
  NEW_SYMPTOM,
  GET_DIAGNOSIS_MASTER_DATA,
  DIAGNOSIS_SUCCESS,
  GET_INVESTIGATION_MASTER_DATA,
  INVESTIGATION_SUCCESS,
  ALLERGY_SUCCESS,
  GET_ALLERGY_MASTER_DATA,
  GET_DISEASE_MASTER_DATA,
  GET_DEPARTMENT_MASTER_DATA,
  GET_ALL_DEPARTMENT_MASTER_DATA,
  DISEASE_SUCCESS,
  GET_PROCEDURE_MASTER_DATA,
  PROCEDURE_SUCCESS,
  NEW_ALLERGY,
  NEW_DIAGNOSIS,
  NEW_DISEASE,
  NEW_PROCEDURE,
  SAVE_MERGED_HOSPITALS_MASTER,
  SHOW_MERGED_HOSPITALS_MASTER,
  NEW_INVESTIGATION,
  GET_DOCTOR_MASTER,
  GET_DOCTOR_DATA,
  GET_DOCTOR_DATA_SUCCESS,
  UPDATE_DOCTOR_SUCCESS_MSG,
  GET_CLIENT_MASTER_DATA,
  MEDIA_MASTER,
  GET_CLIENT_HOSPITAL_DATA,
  GET_CLIENT_DEPARTMENT_DATA,
  GET_CLIENT_DATA,
  GET_CLIENT_MEDIA_DATA,
  UPDATE_CLIENT_HOSPITAl,
  CLIENT_MASTER_ID,
  CLIENT_MASTER_BILLING_DETAILS,
  OPERATOR_REPORT,
  OPREPORT_ERROR,
  GET_APPROVED_DOCTORS,
  SELECTED_HOSPITALS,
  GET_HOSPITAL_FLOOR,
  GET_HOSPITAL_FLOOR_LIST,
  GET_CLIENT_DOCTOR,
  GET_CLIENT_DOCTOR_COUNT,
  ADMIN_PENDING_LIST,
  ADMIN_PENDING_COUNT,
  ADMIN_PENDING_APPROVALS,
  ADMIN_PENDING_APPROVALS_LIST,
  ADMIN_APPROVE_SUCC,
  ADMIN_MASTER_DATA,
  VITAL_DUPLICATE_CHECK,
  GET_FLOOR_NAME,
  GET_CONSULTING_TIME,
  UPDATE_HOS_DEPT,
  UPDATE_DOC_SCEQ,
  UPDATE_FLOOR_DET,
  SCHEDULE_HOS_LIST,
  REMOVE_HOS_DEPT,
  LIST_CAMERA_INFO,
  GET_CLIENT_NAME,
  GET_CLIENT_ID,
  SAVE_CAMERA_INFO,
  GET_CLIENT_PORTAL_INFO,
  HIGHPRIORITYCOUNT,
  GET_LANGUAGE_MASTER_DATA,
  LANGUAGES_SUCCESS,
  GET_LANGUAGE_LABELS,
  GET_LANGUAGES,
} from '../../shared/constants/ActionTypes';
//Inital state
const initialState = {
  symptom_list: [],
  diagnosis_list: [],
  investigation_list: [],
  allergy_list: [],
  reportError: '',
  hospitalDeptsuccess: '',
  disease_list: [],
  department_list: [],
  scheduleHospitalList: [],
  updateDoctorSchedule: '',
  all_department_list: [],
  symptom_success: '',
  media_master: [],
  client_Master_ID: '',
  doctor_update_success: '',
  diagnosis_success: '',
  admin_pending_approval_list: [],
  admin_pending_Approval_count: 0,
  investigation_success: '',
  allergy_success: '',
  disease_success: '',
  updateFloorDet: '',
  procedure_list: [],
  client_data: '',
  clientMediaMaster: [],
  clientBillingDetails: '',
  save_merged_hospitals_master: '',
  procedure_success: '',
  new_symptom: '',
  admin_pending_list: [],
  admin_pending_count: 0,
  admin_approve_succ: '',
  admin_master_data: [],
  vitalDuplicateCheck: '',
  new_allergy: '',
  new_diagnosis: '',
  new_disease: '',
  new_procedure: '',
  new_investigation: '',
  show_merged_hospitals_master: [],
  doctor_master: [],
  doctor_data: '',
  doctor_data_success: '',
  client_master_list_data: [],
  client_hospital_data: [],
  update_client_hospital: '',
  client_dept_data: [],
  hospital_floor_list: [],
  hospital_floor_list_count: 0,
  client_doctor_list: [],
  client_doctor_list_count: 0,
  consulting_time: [],
  removehosDept: '',
  camera_dtls_list: [],
  client_name: '',
  client_Id: 0,
  camera_save_msg: '',
  client_access: '',
  high_priority_count: {},
  language_list: [],
  language_labels: [],
  language_success: '',
  language_master: [],
  gangaramDepartments: [],
};
//Doctor List
const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    //symptom master list
    case GET_SYMPTOM_MASTER_DATA:
      return {
        ...state,
        symptom_list: action.payload,
      };
    case GET_CLIENT_DEPARTMENT_DATA:
      return {
        ...state,
        client_dept_data: action.payload,
      };
    case GET_CLIENT_HOSPITAL_DATA:
      return {
        ...state,
        client_hospital_data: action.payload,
      };
    case CLIENT_MASTER_BILLING_DETAILS:
      return {
        ...state,
        clientBillingDetails: action.payload,
      };
    case GET_CLIENT_DATA:
      return {
        ...state,
        client_data: action.payload,
      };
    case GET_CLIENT_MEDIA_DATA:
      return {
        ...state,
        clientMediaMaster: action.payload,
      };
    case CLIENT_MASTER_ID:
      return {
        ...state,
        client_Master_ID: action.payload,
      };
    case UPDATE_CLIENT_HOSPITAl:
      return {
        ...state,
        update_client_hospital: action.payload,
      };
    case SYMPTOM_SUCCESS:
      return {
        ...state,
        symptom_success: action.payload,
      };

    //diagnosis master list
    case GET_DIAGNOSIS_MASTER_DATA:
      return {
        ...state,
        diagnosis_list: action.payload,
      };
    //diagnosis result
    case DIAGNOSIS_SUCCESS:
      return {
        ...state,
        diagnosis_success: action.payload,
      };
    //Investigation master list
    case GET_INVESTIGATION_MASTER_DATA:
      return {
        ...state,
        investigation_list: action.payload,
      };
    //Investigation result
    case INVESTIGATION_SUCCESS:
      return {
        ...state,
        investigation_success: action.payload,
      };
    //Allergy master list
    case GET_ALLERGY_MASTER_DATA:
      return {
        ...state,
        allergy_list: action.payload,
      };
    //Allergy result
    case ALLERGY_SUCCESS:
      return {
        ...state,
        allergy_success: action.payload,
      };
    //Allergy result
    case LANGUAGES_SUCCESS:
      return {
        ...state,
        language_success: action.payload,
      };
    //Disease master list
    case GET_DISEASE_MASTER_DATA:
      return {
        ...state,
        disease_list: action.payload,
      };
    //Get Department master list
    case GET_DEPARTMENT_MASTER_DATA:
      return {
        ...state,
        department_list: action.payload,
      };

    //Get Language master list
    case GET_LANGUAGE_MASTER_DATA:
      return {
        ...state,
        language_list: action.payload,
      };
    //Get Language labels list
    case GET_LANGUAGE_LABELS:
      return {
        ...state,
        language_labels: action.payload,
      };
    //Get All Department master list
    case GET_ALL_DEPARTMENT_MASTER_DATA:
      return {
        ...state,
        all_department_list: action.payload,
      };
    //Disease result
    case DISEASE_SUCCESS:
      return {
        ...state,
        disease_success: action.payload,
      };
    case GET_PROCEDURE_MASTER_DATA:
      return {
        ...state,
        procedure_list: action.payload,
      };
    case PROCEDURE_SUCCESS:
      return {
        ...state,
        procedure_success: action.payload,
      };

    case NEW_SYMPTOM:
      return {
        ...state,
        new_symptom: action.payload,
      };
    case NEW_ALLERGY:
      return {
        ...state,
        new_allergy: action.payload,
      };
    case NEW_DIAGNOSIS:
      return {
        ...state,
        new_diagnosis: action.payload,
      };
    case NEW_DISEASE:
      return {
        ...state,
        new_disease: action.payload,
      };

    case NEW_PROCEDURE:
      return {
        ...state,
        new_procedure: action.payload,
      };
    case NEW_INVESTIGATION:
      return {
        ...state,
        new_investigation: action.payload,
      };
    case SHOW_MERGED_HOSPITALS_MASTER:
      return {
        ...state,
        show_merged_hospitals_master: action.payload,
      };
    case SAVE_MERGED_HOSPITALS_MASTER:
      return {
        ...state,
        save_merged_hospitals_master: action.payload,
      };
    case GET_DOCTOR_MASTER:
      return {
        ...state,
        doctor_master: action.payload,
      };

    case GET_DOCTOR_DATA:
      return {
        ...state,
        doctor_data: action.payload,
      };
    case GET_DOCTOR_DATA_SUCCESS:
      return {
        ...state,
        doctor_data_success: action.payload,
      };

    case UPDATE_DOCTOR_SUCCESS_MSG:
      return {
        ...state,
        doctor_update_success: action.payload,
      };

    //Client master list
    case GET_CLIENT_MASTER_DATA:
      return {
        ...state,
        client_master_list_data: action.payload,
      };
    case MEDIA_MASTER:
      return {
        ...state,
        media_master: action.payload,
      };

    case OPERATOR_REPORT:
      return {
        ...state,
        operator_data: action.payload,
      };
    case OPREPORT_ERROR:
      return {
        ...state,
        reportError: action.payload,
      };
    case GET_APPROVED_DOCTORS:
      return {
        ...state,
        approvedDoctors: action.payload,
      };
    case REMOVE_HOS_DEPT:
      return {
        ...state,
        removehosDept: action.payload,
      };
    case UPDATE_FLOOR_DET:
      return {
        ...state,
        updateFloorDet: action.payload,
      };
    case SCHEDULE_HOS_LIST:
      return {
        ...state,
        scheduleHospitalList: action.payload,
      };
    case UPDATE_HOS_DEPT:
      return {
        ...state,
        hospitalDeptsuccess: action.payload,
      };
    case UPDATE_DOC_SCEQ:
      return {
        ...state,
        updateDoctorSchedule: action.payload,
      };
    case SELECTED_HOSPITALS:
      return {
        ...state,
        selected_client_hospitals: action.payload,
      };
    case GET_HOSPITAL_FLOOR:
      return {
        ...state,
        hospital_floor_list: action.payload,
      };
    case GET_HOSPITAL_FLOOR_LIST:
      return {
        ...state,
        hospital_floor_list_count: action.payload,
      };
    case GET_CLIENT_DOCTOR:
      return {
        ...state,
        client_doctor_list: action.payload,
      };
    case GET_CLIENT_DOCTOR_COUNT:
      return {
        ...state,
        client_doctor_list_count: action.payload,
      };
    case GET_FLOOR_NAME:
      return {
        ...state,
        floor_names: action.payload,
      };
    case GET_CONSULTING_TIME:
      return {
        ...state,
        consulting_time: action.payload,
      };

    case LIST_CAMERA_INFO:
      return {
        ...state,
        camera_dtls_list: action.payload,
      };
    case GET_CLIENT_NAME:
      return {
        ...state,
        client_name: action.payload,
      };
    case GET_CLIENT_ID:
      return {
        ...state,
        client_Id: action.payload,
      };
    case SAVE_CAMERA_INFO:
      return {
        ...state,
        camera_save_msg: action.payload,
      };
    case GET_CLIENT_PORTAL_INFO:
      return {
        ...state,
        client_access: action.payload,
      };
    case ADMIN_PENDING_LIST:
      return {
        ...state,
        admin_pending_list: action.payload,
      };
    //for show the notification count on dashboard for admin login
    case ADMIN_PENDING_APPROVALS:
      return {
        ...state,
        admin_pending_Approval_count: action.payload,
      };
    case ADMIN_PENDING_APPROVALS_LIST:
      return {
        ...state,
        admin_pending_approval_list: action.payload,
      };
    case ADMIN_PENDING_COUNT:
      return {
        ...state,
        admin_pending_count: action.payload,
      };
    case ADMIN_APPROVE_SUCC:
      return {
        ...state,
        admin_approve_succ: action.payload,
      };
    case ADMIN_MASTER_DATA:
      return {
        ...state,
        admin_master_data: action.payload,
      };
    case VITAL_DUPLICATE_CHECK:
      return {
        ...state,
        vitalDuplicateCheck: action.payload,
      };
    case HIGHPRIORITYCOUNT:
      return {
        ...state,
        high_priority_count: action.payload,
      };
    case GET_LANGUAGES:
      return {
        ...state,
        language_master: action.payload,
      };
    default:
      return state;
  }
};
export default masterDataReducer;
