/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';

const amazonroutesConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.Amazondashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'amazondashboard',
    title: 'TeleConsultation Dashboard',
    messageId: 'sidebar.Amazondashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.amazonAdminRoles,
    url: '/amazondashboard',
  },
];
export default amazonroutesConfig;
