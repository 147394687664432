/************
 * Developed by : Shiva Software Solutions
 * Date : 05-03-2024
 * Descriptions : Route page for report page
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const digitizationreportpageConfigs = [
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/digitizationreports',
        component: React.lazy(() => import('./reportPage')),
      },
    ],
  },
];
