/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_START,
  ERX_DATA_HTML,
  ERX_DATA_HTML_NO_DATA,
  PATIENT_COMPLETED_DATA,
  PATIENT_COMPLETED_COUNT,
  PATIENT_NAME_LIST,
  ERX_IMG_ARR,
  ERX_IMG_OCR,
  GANGA_MEDSUGG,
  FETCH_SUCCESS,
  OCR_DETAIL,
  FETCH_ERROR,
  PATIENT_LISTS,
  SEARCH_REPORTS_DATA,
  SEARCH_REPORTS_DATA_COUNT,
  INVESTIGATIONS_LIST,
  REPORT_PATIENT_LIST,
  INDIVIDUAL_PATIENT_APPOINTMENT_COUNT,
  INDIVIDUAL_PATIENT_APPOINTMENT_LIST,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import IntlMessages from '../../@crema/utility/IntlMessages';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Patient.js';

//get Generate erx html
export const GenerateERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/pateRxMgmt/generateHTML', body)
          .then((data) => {
            dispatch({type: FETCH_SUCCESS});
            if (data && data.status && data.status === 200) {
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_HTML,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_HTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'GetCovidVitalsListData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_HTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'GetCovidVitalsListData',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};

//amazon

//get Generate erx html
export const GenerateAmazonERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_HTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/teleConsultant/generateAmazonHTML', body)
          .then((data) => {
            if (data && data.status && data.status === 200) {
              dispatch({type: FETCH_SUCCESS});
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_HTML,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: ERX_DATA_HTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_HTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'generateAmazonHTML',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_HTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'generateAmazonHTML',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_HTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'generateAmazonHTML',
          '0',
          err,
        ),
      );
    }
  };
};

//Get Patient completed List data
export const onGetPatientCompletedList = ({
  mobileNbr,
  patId,
  limit,
  offset,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {
      mobileNbr,
      patId,
      limit,
      offset,
    };
    try {
      jwtAxios
        .post('v1/patMgmt/patientCompletedListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                mobileNbr,
                patId,
                limit,
                offset,
                jwtToken,
              };
              jwtAxios
                .post('v1/patMgmt/patientCompletedList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: PATIENT_COMPLETED_COUNT,
                      payload: data.data.body.totalcount,
                    });
                    dispatch({
                      type: PATIENT_COMPLETED_DATA,
                      payload: data.data.body.data,
                    });
                    dispatch({
                      type: PATIENT_NAME_LIST,
                      payload: data.data.body.patNameList,
                    });
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            erxid: getdata.erxImageOCR[i].erxId,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR,
                          payload: imgocr,
                        });
                      }
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPatientCompletedList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPatientCompletedList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientCompletedList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      console.log('error!!!!', err.response.message);
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientCompletedList',
          '0',
          err,
        ),
      );
    }
  };
};

// Get InvestigationCategory  data
export const onGetPatientList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/onGetPatientListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/onGetPatientList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: PATIENT_LISTS,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetPatientList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPatientList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetSearchPatientReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getSearchPatientReportsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getSearchPatientReports', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: SEARCH_REPORTS_DATA,
                    payload: data.data.body.reportData,
                  });
                  dispatch({
                    type: SEARCH_REPORTS_DATA_COUNT,
                    payload: data.data.body.reportCount,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchPatientReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchPatientReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchPatientReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchPatientReports',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Investigations
export const onGetInvestigations = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/patMgmt/searchInvestigationAndPatientjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patMgmt/searchInvestigationAndPatient', value)
              .then((data) => {
                if (data.data.status === 200) {
                  if (data.data.body?.inves_lists?.length > 0) {
                    var arraydata = data.data.body.inves_lists?.map((e, i) => {
                      return {
                        title: e.description,
                        id: e.investigationid,
                        hindi_title: '',
                        category: '',
                        hindi_category: '',
                      };
                    });
                    dispatch({
                      type: INVESTIGATIONS_LIST,
                      payload: arraydata,
                    });
                  } else {
                    dispatch({
                      type: INVESTIGATIONS_LIST,
                      payload: [],
                    });
                  }
                  if (data.data.body?.patientNameLists?.length > 0) {
                    dispatch({
                      type: REPORT_PATIENT_LIST,
                      payload: data.data.body.patientNameLists,
                    });
                  } else {
                    dispatch({
                      type: REPORT_PATIENT_LIST,
                      payload: [],
                    });
                  }
                } else {
                  dispatch({
                    type: INVESTIGATIONS_LIST,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigations',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigations',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigations',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigations',
          '0',
          err,
        ),
      );
    }
  };
};

//getAppointmentsJWt
export const onGetPatientAppointments = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getAppointmentsJwt', value)
        .then((data) => {
          // console.log(data)
          if (data.status === 200) {
            //Call pending list data - Get pending list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = value;
              bodytoken['jwtToken'] = jwtToken;
              jwtAxios
                .post('v1/pateRxMgmt/getAppointments', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body.data;
                    dispatch({
                      type: INDIVIDUAL_PATIENT_APPOINTMENT_COUNT,
                      payload: data.data.body.totalCount,
                    });
                    dispatch({
                      type: INDIVIDUAL_PATIENT_APPOINTMENT_LIST,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPatientAppointments',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              console.log('error!!!!', err.response.message);
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPatientAppointments',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientAppointments',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientAppointments',
          '0',
          err,
        ),
      );
    }
  };
};
