/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  GET_USERMANAGE_DATA,
  DELETE_USER_DATA,
  GET_GUDMEDSUCCESS_DATA,
  GET_GUDMEDUPDATE_DATA,
  GET_GUDMED_USER,
  USER_REGNO_ERROR,
  RESET_USER_PASWD,
  GET_USERCATEGORY_DATA,
  GET_EDITLOAD_DEPT,
  GET_EDITLOAD_CLIENT,
  GET_ROLE_DATA,
  GET_TC_DOCTORTIME,
} from '../../shared/constants/ActionTypes';

const initialState = {
  users: [],
  deleteuserdata: null,
  message: '',
  editloaddata: {},
  department: [],
  clientLst: [],
  updatemsg: '',
  resetpaswd: false,
  get_usercategory_data: [],
  get_role_data: [],
  user_regno_error: '',
  tcConsultTimes: [],
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //Assign user data
    case GET_USERMANAGE_DATA:
      return {
        ...state,
        users: action.payload,
      };
    case USER_REGNO_ERROR:
      return {
        ...state,
        user_regno_error: action.payload,
      };
    case GET_USERCATEGORY_DATA:
      return {
        ...state,
        get_usercategory_data: action.payload,
      };
    case GET_ROLE_DATA:
      return {
        ...state,
        get_role_data: action.payload,
      };
    //Assign Get user  success data
    case GET_GUDMEDSUCCESS_DATA:
      return {
        ...state,
        message: action.payload,
      };

    //Assign Get user  update data
    case GET_GUDMEDUPDATE_DATA:
      return {
        ...state,
        updatemsg: action.payload,
      };
    //Assign Get user data
    case GET_GUDMED_USER:
      return {
        ...state,
        editloaddata: action.payload,
      };
    //Assign user delete response
    case DELETE_USER_DATA:
      return {
        ...state,
        deleteuserdata: action.payload,
      };
    //Assign success reset paswd
    case RESET_USER_PASWD:
      return {
        ...state,
        resetpaswd: action.payload,
      };
    case GET_EDITLOAD_DEPT:
      return {
        ...state,
        department: action.payload,
      };
    case GET_EDITLOAD_CLIENT:
      return {
        ...state,
        clientLst: action.payload,
      };
    case GET_TC_DOCTORTIME:
      return {
        ...state,
        tcConsultTimes: action.payload,
      };
    default:
      return state;
  }
};
export default userManagementReducer;
