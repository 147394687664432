/************
 * Developed by : Shiva Software Solutions
 * Date : 07-08-2021
 * Descriptions : User Mangament api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PATIENTMASTER_DATA,
  GET_PRESCRIPTION_PATIENTMASTER_DATA,
  GET_PRESCRIPTION_PATIENTMASTER_DATA_COUNT,
  GET_PRESCRIPTION_PATIENTMASTER_EXPORT,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import {genderMaster} from '../../shared/constants/AppConst';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'patientMaster.js';

const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';

//Decrypt password
const decryptpassword = function ({data}) {
  var decipher = crypto.createDecipher(algorithm, password);
  var dec = decipher.update(data, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};
//get patient master list using jwt token
export const onGetPatientMaster = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/patMasters/getPatientsjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/patMasters/getPatients', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: GET_PATIENTMASTER_DATA,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPatientMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPatientMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientMaster',
          '0',
          err,
        ),
      );
    }
  };
};

//get prescription patient master list using jwt token
export const onGetPrescriptionPatientMaster = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/patMasters/getPrescriptionPatientsjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get prescription  data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/patMasters/getPrescriptionPatients', body)
                .then((data) => {
                  if (data.status === 200) {
                    data.data.body.data.map((e) => {
                      e.mobileNo = e.mobileNo
                        ? decryptpassword({data: e.mobileNo})
                        : '';
                      e.email = e.email ? decryptpassword({data: e.email}) : '';

                      return e;
                    });

                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: GET_PRESCRIPTION_PATIENTMASTER_DATA,
                      payload: data.data.body.data,
                    });
                    dispatch({
                      type: GET_PRESCRIPTION_PATIENTMASTER_DATA_COUNT,
                      payload: data.data.body.totalcount,
                    });

                    if (data.data.body.exportData.length > 0) {
                      data.data.body.exportData.map((e) => {
                        e.mobileNo = e.mobileNo
                          ? decryptpassword({data: e.mobileNo})
                          : '';
                        e.email = e.email
                          ? decryptpassword({data: e.email})
                          : '';

                        return e;
                      });

                      dispatch({
                        type: GET_PRESCRIPTION_PATIENTMASTER_EXPORT,
                        payload: data.data.body.exportData,
                      });
                    } else {
                      dispatch({
                        type: GET_PRESCRIPTION_PATIENTMASTER_EXPORT,
                        payload: [],
                      });
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPrescriptionPatientMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPrescriptionPatientMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPrescriptionPatientMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPrescriptionPatientMaster',
          '0',
          err,
        ),
      );
    }
  };
};
