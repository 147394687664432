import {
  SET_INITIAL_PATH,
  TOGGLE_NAV_COLLAPSED,
  SETTING_SUCCESS,
  GET_TRAINED_DATA,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  navCollapsed: false,
  initialPath: '/',
  settingSuccess: '',
};
//Setting reducer
const settingsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false,
      };

    case TOGGLE_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: !state.navCollapsed,
      };

    case SET_INITIAL_PATH:
      return {
        ...state,
        initialPath: action.payload,
      };
    case SETTING_SUCCESS:
      return {
        ...state,
        settingSuccess: action.payload,
      };
    case GET_TRAINED_DATA:
      return {
        ...state,
        getTrainedData_count: action.payload,
      };

    default:
      return state;
  }
};

export default settingsReducer;
