/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  GET_PATIENTMASTER_DATA,
  GET_PRESCRIPTION_PATIENTMASTER_DATA,
  GET_PRESCRIPTION_PATIENTMASTER_DATA_COUNT,
  GET_PRESCRIPTION_PATIENTMASTER_EXPORT,
} from '../../shared/constants/ActionTypes';

const initialState = {
  patients: [],
  prescriptionpatient: [],
  prescriptionpatientexport: [],
  prescriptionpatientcount: 0,
};
//Get patient master reducer
const patientMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    //get patient data
    case GET_PATIENTMASTER_DATA:
      return {
        ...state,
        patients: action.payload,
      };
    //Get prescription data
    case GET_PRESCRIPTION_PATIENTMASTER_DATA:
      return {
        ...state,
        prescriptionpatient: action.payload,
      };
    case GET_PRESCRIPTION_PATIENTMASTER_DATA_COUNT:
      return {
        ...state,
        prescriptionpatientcount: action.payload,
      };
    case GET_PRESCRIPTION_PATIENTMASTER_EXPORT:
      return {
        ...state,
        prescriptionpatientexport: action.payload,
      };
    default:
      return state;
  }
};
export default patientMasterReducer;
