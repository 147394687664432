/************
 * Developed by : Shiva Software Solutions
 * Date : 07-08-2021
 * Descriptions : User Mangament api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PRESCRIPTION_DOCTOR,
  SAVE_MAPPED_DOCTORS,
  GET_MERGED_DOCTOR,
  GET_MAPPED_PRESCRIPTION_DOCTOR,
  GET_PRIMARY_SEARCH_DOCTOR,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'doctorMaster.js';
//get doctor master list using jwt token
export const onGetPrescriptionDoctorList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showallDoctorsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showallDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_PRESCRIPTION_DOCTOR,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPrescriptionDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPrescriptionDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPrescriptionDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPrescriptionDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

//get doctor master list using jwt token
export const onShowMergedDoctorList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showMergedDoctorsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showMergedDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_MERGED_DOCTOR,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetPrimaryDoctorList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showPrimarySearchDoctorsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showPrimarySearchDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_PRIMARY_SEARCH_DOCTOR,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPrimaryDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPrimaryDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPrescriptionDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPrescriptionDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

//save doctor details
export const onSaveMappedDoctors = (body, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/saveMappedDoctorsjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/saveMappedDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    let message = data.data.message;
                    if (message.includes('Error')) {
                      dispatch({
                        type: SAVE_MAPPED_DOCTORS,
                        payload: 'Failure',
                      });
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: SAVE_MAPPED_DOCTORS,
                        payload: 'success',
                      });
                      // history.push('/doctor-deduplication');
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onUpdateDoctorMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onUpdateDoctorMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetMappedPrescriptionDoctorList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showprescriptionDoctorsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showprescriptionDoctors', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_MAPPED_PRESCRIPTION_DOCTOR,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetMappedPrescriptionDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetMappedPrescriptionDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetMappedPrescriptionDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetMappedPrescriptionDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};
