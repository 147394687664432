/************
 * Developed by : Shiva Software Solutions
 * Date : 10-01-2022
 * Descriptions : Route for dahboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const covidVitalsConfig = [
  //Redirect to dashboard page
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/covidVitals',
        component: React.lazy(() => import('./covidVitalsPage/index')),
      },
    ],
  },
];
