/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {GET_ABHAMASTER_DATA} from '../../shared/constants/ActionTypes';

const initialState = {
  abhaUsers: [],
};
//Get patient master reducer
const abhaUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    //get patient data
    case GET_ABHAMASTER_DATA:
      return {
        ...state,
        abhaUsers: action.payload,
      };

    default:
      return state;
  }
};
export default abhaUsersReducer;
