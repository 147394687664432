import {
  SHOW_ALL_HOSPITALS,
  SHOW_MERGED_HOSPITALS,
  SAVE_MERGED_HOSPITALS,
  SELECTED_HOSPITAL,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  show_all_hospitals: [],
  show_merged_hospitals: [],
  // show_mapped_doctors: [],
  save_mapped_hospitals: '',
  selected_hospital: '',
};

const MergingHospitalsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ALL_HOSPITALS:
      return {
        ...state,
        show_all_hospitals: action.payload,
      };

    case SHOW_MERGED_HOSPITALS:
      return {
        ...state,
        show_merged_hospitals: action.payload,
      };

    case SAVE_MERGED_HOSPITALS:
      return {
        ...state,
        save_mapped_hospitals: action.payload,
      };
    case SELECTED_HOSPITAL:
      return {
        ...state,
        selected_hospital: action.payload,
      };
    //   case GET_MERGED_DOCTOR:
    //     return {
    //       ...state,
    //       show_mapped_doctors: action.payload,
    //     };

    default:
      return state;
  }
};
export default MergingHospitalsReducer;
