/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Define reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import digitizationReducer from './Digitization';
import Common from './Common';
import Dashboard from './Dashboard';
import UserManageData from './userManagement';
import PatientMasterData from './patientMaster';
import DoctorMasterData from './doctorMaster';
import Auth from './Auth';
import Master from './Master';
import CovidVitals from './CovidVitals';
import UploadeRx from './UploadeRx';
import AbhaUsers from './abhaUsers';
import Mapping_Patient from './MergePatient';
import patient from './Patient';
import vendors from './vendor';
import report from './report';
import nerReport from './nerReport';
import amazonreport from './amazonreport';
import cameraDashboard from './cameraDashboard';
import nerReportentityruler from './nerReportentityruler';
import mergeDoctor from './mergeDoctors';
import mergeHospitals from './mergeHospitals';
import ipdReducer from './ipdReducers';

//Index reducers
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    dashboard: Dashboard,
    digitization: digitizationReducer,
    userManage: UserManageData,
    patientMasters: PatientMasterData,
    doctorMasters: DoctorMasterData,
    common: Common,
    master: Master,
    covidVitals: CovidVitals,
    UploadeRx: UploadeRx,
    abhaUsers: AbhaUsers,
    Mapping_Patient: Mapping_Patient,
    patient: patient,
    vendorData: vendors,
    reportData: report,
    nerReportData: nerReport,
    amazonreportData: amazonreport,
    nerEntityData: nerReportentityruler,
    mergeDoctor: mergeDoctor,
    mergeHospitals: mergeHospitals,
    cameraDashboard: cameraDashboard,
    ipdReducer:ipdReducer
  });
export default reducers;
