/************
 * Developed by : Shiva Software Solutions
 * Date : 07-08-2021
 * Descriptions : User Mangament api calling
 ************/
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SAVE_MAPPED_DOCTORS,
  SHOW_MERGED_HOSPITALS,
  SHOW_ALL_HOSPITALS,
  GET_DUPLICATE_HOSPITALS,
  SAVE_MERGED_HOSPITALS,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'doctorMaster.js';
//get doctor master list using jwt token
export const onShowallHospitalsList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showallHospitalsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showallHospitals', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: SHOW_ALL_HOSPITALS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowallHospitals',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowallHospitals',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowallHospitals',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowallHospitals',
          '0',
          err,
        ),
      );
    }
  };
};

//get doctor master list using jwt token
export const onShowMergedHospitalsList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/showMergedHospitalsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/showMergedHospitals', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: SHOW_MERGED_HOSPITALS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onShowMergedHospitalsList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onShowMergedHospitalsList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onShowMergedHospitalsList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onShowMergedHospitalsList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetduplicateHospitalsList = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/getduplicateHospitalsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/getduplicateHospitals', body)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_DUPLICATE_HOSPITALS,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPrimaryDoctorList',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPrimaryDoctorList',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPrescriptionDoctorList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPrescriptionDoctorList',
          '0',
          err,
        ),
      );
    }
  };
};

//save doctor details
export const onSaveMappedHospitals = (body, history) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call doctor list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorMasters/savemergedHospitalsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call doctor User list data - Get user list data using token
            try {
              body['jwtToken'] = data.data.body['token'];

              jwtAxios
                .post('v1/doctorMasters/savemergedHospitals', body)
                .then((data) => {
                  if (data.status === 200) {
                    let message = data.data.message;
                    if (message.includes('Error')) {
                      dispatch({
                        type: SAVE_MERGED_HOSPITALS,
                        payload: 'Failure',
                      });
                    } else {
                      dispatch({type: FETCH_SUCCESS});
                      dispatch({
                        type: SAVE_MERGED_HOSPITALS,
                        payload: 'success',
                      });
                      // history.push('/doctor-deduplication');
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onUpdateDoctorMaster',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onUpdateDoctorMaster',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateDoctorMaster',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateDoctorMaster',
          '0',
          err,
        ),
      );
    }
  };
};
