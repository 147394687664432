/************
 * Developed by : Shiva Software Solutions
 * Date : 22-06-2021
 * Descriptions : User Mangament api calling
 ************/
import {
  FETCH_ERROR,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  GET_USERMANAGE_DATA,
  GET_USERCATEGORY_DATA,
  GET_ROLE_DATA,
  DELETE_USER_DATA,
  GET_GUDMEDSUCCESS_DATA,
  GET_GUDMEDUPDATE_DATA,
  USER_REGNO_ERROR,
  GET_GUDMED_USER,
  GET_EDITLOAD_DEPT,
  GET_EDITLOAD_CLIENT,
  RESET_USER_PASWD,
  GET_TC_DOCTORTIME,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'userManagement.js';
//Get User list data
export const onGetUserManagementData = ({userId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/userMgmt/getusersjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, jwtToken, userCode};
              jwtAxios
                .post('v1/userMgmt/getusers', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    var arraydata = data.data.body.list.map((e, i) => {
                      return {
                        fName: e.fName,
                        lName: e.lName,
                        userId: e.userId,
                        email: e.email,
                        roleName: e.roleName,
                        mobileNbr: e.mobileNbr,
                        status: e.status,
                        agentId: e.agentId,
                        agentCode: e.agentCode,
                        statusName: e.statusName,
                        sessionStatus: e.sessionstatus,
                        userCode: e.userCode,
                      };
                    });
                    dispatch({
                      type: GET_USERMANAGE_DATA,
                      payload: arraydata,
                    });
                    dispatch({
                      type: GET_USERCATEGORY_DATA,
                      payload: data.data.body.category,
                    });
                    dispatch({
                      type: GET_ROLE_DATA,
                      payload: data.data.body.userrole,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetUserManagementData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetUserManagementData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUserManagementData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetUserManagementData',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete user data
export const onDeleteUser = ({
  email,
  userId,
  loginuserid,
  userCode,
  deleteuserCode,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {email, userId, userCode, deleteuserCode};

    //Call Delete user - get Token
    try {
      jwtAxios
        .post('v1/userMgmt/deleteUserjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Delete user data - Delete userdata using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, userCode, deleteuserCode, jwtToken};
              jwtAxios
                .post('v1/userMgmt/deleteUser', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch({
                      type: DELETE_USER_DATA,
                      payload: getuserdata,
                    });
                    dispatch(
                      onGetUserManagementData({
                        userId: loginuserid,
                        userCode: userCode,
                      }),
                    );
                    setTimeout(() => {
                      if (!data.data.message.includes('Error')) {
                        dispatch({
                          type: SHOW_MESSAGE,
                          payload: getuserdata,
                        });
                      } else {
                        var userdeletemsg = data.data.message.split(':')[1];
                        dispatch({
                          type: FETCH_ERROR,
                          payload: userdeletemsg,
                        });
                      }
                    }, 2000);
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetUserManagementData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetUserManagementData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUserManagementData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetUserManagementData',
          '0',
          err,
        ),
      );
    }
  };
};

// change password
export const onchangePassword = ({email, userId, userCode, pwd, oldpwd}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {email, userId, userCode, oldpwd, pwd};
    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/userMgmt/changePaswdJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token
            try {
              if (data.data.hasOwnProperty.call(data.data, 'message')) {
                dispatch({type: FETCH_ERROR, payload: data.data.message});
                dispatch({
                  type: RESET_USER_PASWD,
                  payload: false,
                });
                return;
              }
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                email,
                userId,
                userCode,
                pwd,
                oldpwd,
                jwtToken,
              };
              jwtAxios
                .post('v1/userMgmt/changePaswd', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch({
                      type: SHOW_MESSAGE,
                      payload: getuserdata,
                    });
                    dispatch({
                      type: RESET_USER_PASWD,
                      payload: true,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onchangePassword',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onchangePassword',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onchangePassword',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onchangePassword',
          '0',
          err,
        ),
      );
    }
  };
};

// Reset User Password
export const onresetPassword = ({userCode, resetUserCode, pwd}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userCode, resetUserCode, pwd};

    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/userMgmt/resetUserPaswdJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token
            try {
              if (data.data.hasOwnProperty.call(data.data, 'message')) {
                dispatch({type: FETCH_ERROR, payload: data.data.message});

                return;
              }
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                resetUserCode,
                pwd,

                jwtToken,
              };
              jwtAxios
                .post('v1/userMgmt/resetUserPaswd', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch({
                      type: SHOW_MESSAGE,
                      payload: getuserdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onresetPassword',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onresetPassword',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onresetPassword',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onresetPassword', '0', err),
      );
    }
  };
};

// save user api
export const onSaveGudMedUser = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/userMgmt/createjwt', value)
        .then((data) => {
          if (data.status === 200 && data.data && data.data.message) {
            dispatch({
              type: FETCH_ERROR,
              payload: data.data.message.split(':')[1],
            });
            dispatch({
              type: USER_REGNO_ERROR,
              payload: data.data.message.split(':')[1],
            });
          } else {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/userMgmt/create', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_GUDMEDSUCCESS_DATA,
                      payload: data.data.message,
                    });
                    dispatch(
                      onGetUserManagementData({
                        userId: value.userId,
                        userCode: value.userCode,
                      }),
                    );
                    dispatch({
                      type: SHOW_MESSAGE,
                      payload: data.data.message,
                    });
                  } else {
                    if (data.data.message.includes('Error')) {
                      dispatch({
                        type: USER_REGNO_ERROR,
                        payload: data.data.message.split(':')[1],
                      });
                    }
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onSaveGudMedUser',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onSaveGudMedUser',
                  '0',
                  err,
                ),
              );
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSaveGudMedUser',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSaveGudMedUser',
          '0',
          err,
        ),
      );
    }
  };
};

// edit load event
export const onGeteditloadGudmed = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/userMgmt/getuserJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/userMgmt/getuser', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: GET_GUDMED_USER,
                    payload: data.data.body?.resultRows,
                  });

                  dispatch({
                    type: GET_TC_DOCTORTIME,
                    payload:
                      data.data.body?.tcConsultTime.length > 0
                        ? data.data.body.tcConsultTime
                        : [],
                  });
                  dispatch({
                    type: GET_EDITLOAD_DEPT,
                    payload: data.data.body?.department,
                  });
                  dispatch({
                    type: GET_EDITLOAD_CLIENT,
                    payload: data.data.body?.clientList,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onSaveGudMedUser',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onSaveGudMedUser',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onSaveGudMedUser',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onSaveGudMedUser',
          '0',
          err,
        ),
      );
    }
  };
};

// update api
export const onUpdateGudMedUser = (value) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/userMgmt/updatejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/userMgmt/update', value)
              .then(async (data) => {
                if (data.data.status === 200) {
                  if (!data.data.message.includes('Error')) {
                    dispatch({
                      type: SHOW_MESSAGE,
                      payload: data.data.message,
                    });
                    setTimeout(() => {
                      dispatch(
                        onGetUserManagementData({
                          userId: value.userId,
                          userCode: value.userCode,
                        }),
                      );
                    }, 1000);
                    dispatch({
                      type: GET_GUDMEDUPDATE_DATA,
                      payload: data.data.message,
                    });
                  }
                  var userupadte = data.data.message.split(':')[1];
                  dispatch({type: FETCH_ERROR, payload: userupadte});
                } else {
                  if (data.data.message.includes('Error')) {
                    dispatch({
                      type: USER_REGNO_ERROR,
                      payload: data.data.message.split(':')[1],
                    });
                  }
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdateGudMedUser',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateGudMedUser',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateGudMedUser',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateGudMedUser',
          '0',
          err,
        ),
      );
    }
  };
};
