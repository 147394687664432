import {
  GET_PRESCRIPTION_DOCTOR,
  GET_MERGED_DOCTOR,
  SAVE_MAPPED_DOCTORS,
  GET_PRIMARY_SEARCH_DOCTOR,
  SELECTED_DOCTOR,
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  prescription_doctor_list: [],
  search_primary_doctor_list: [],
  show_mapped_doctors: [],
  save_mapped_doctors: '',
  selected_doctor: '',
};

const MergingPatientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRESCRIPTION_DOCTOR:
      return {
        ...state,
        prescription_doctor_list: action.payload,
      };

    case GET_PRIMARY_SEARCH_DOCTOR:
      return {
        ...state,
        search_primary_doctor_list: action.payload,
      };

    case SAVE_MAPPED_DOCTORS:
      return {
        ...state,
        save_mapped_doctors: action.payload,
      };
    case GET_MERGED_DOCTOR:
      return {
        ...state,
        show_mapped_doctors: action.payload,
      };
    case SELECTED_DOCTOR:
      return {
        ...state,
        selected_doctor: action.payload,
      };
    default:
      return state;
  }
};
export default MergingPatientReducer;
