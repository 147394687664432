/************
 * Developed by : Shiva Software Solutions
 * Date : 24-07-2023
 * Descriptions : NERReports page api calling
 ************/
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import {
  NER_REPORT_ENTITY_RULER,
  NER_SUGGESTION_SUCCESS,
  FETCH_START,
  FETCH_ERROR,
  FETCH_SUCCESS,
} from '../../shared/constants/ActionTypes';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'nerReportentityruler.js';

export const nerReportentityruler = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/nerReportentityruler', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/nerReportentityruler', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: NER_REPORT_ENTITY_RULER,
                    payload: data.data.body,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'nerReportentityruler',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'nerReportentityruler',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'nerReportentityruler',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'nerReportentityruler',
          '0',
          err,
        ),
      );
    }
  };
};

export const nerSuggestionSuccess = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/nerSuggestionSuccess', value)
        .then((data) => {
          if (data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: NER_SUGGESTION_SUCCESS,
              payload: data.data.body,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'nerSuggestionSuccess',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'nerSuggestionSuccess',
          '0',
          err,
        ),
      );
    }
  };
};
