/************
 * Developed by : Shiva Software Solutions
 * Date : 31-10-2022
 * Descriptions : Route for dahboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const mergedHospitalsConfig = [
  //Redirect to dashboard page
  {
    auth: authRole.user,
    routes: [
      {
        path: '/hospital-deduplication-tabs',
        component: React.lazy(() => import('./MergeTabs')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/unverifiedhospitals',
        component: React.lazy(() => import('./unverifiedhospitals')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/verifiedhospitals',
        component: React.lazy(() => import('./verifiedhospitals')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/hospital-deduplication',
        component: React.lazy(() => import('./mergehospitals')),
      },
    ],
  },
];
