/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import NewVendor from 'modules/userManagement/vendorManage/newVendor';
import {
  GET_VENDOR_DATA,
  GET_VENDORCREDENTIAL_DATA,
  GET_VENDORCREDENTIAL_DATA_COUNT,
  GET_VENDORUPDATE_DATA,
  NEW_VENDOR_NOTIFI,
} from '../../shared/constants/ActionTypes';

const initialState = {
  vendorList: [],
  vendorupdate: '',
  vendorCredentialList: [],
  vendorCredentialListCount: 0,
  newVendor: '',
};

const vendorReducer = (state = initialState, action) => {
  switch (action.type) {
    //Assign vendor data
    case GET_VENDOR_DATA:
      return {
        ...state,
        vendorList: action.payload,
      };

    case GET_VENDORUPDATE_DATA:
      return {
        ...state,
        vendorupdate: action.payload,
      };
    case NEW_VENDOR_NOTIFI:
      return {
        ...state,
        newVendor: action.payload,
      };

    case GET_VENDORCREDENTIAL_DATA:
      return {
        ...state,
        vendorCredentialList: action.payload,
      };
    case GET_VENDORCREDENTIAL_DATA_COUNT:
      return {
        ...state,
        vendorCredentialListCount: action.payload,
      };

    default:
      return state;
  }
};
export default vendorReducer;
