/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route for dahboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const amazonDashBoardConfigs = [
  //Redirect to dashboard page
  {
    auth: authRole.amazonUser,
    routes: [
      {
        path: '/amazondashboard',
        component: React.lazy(() => import('./DashboardPage/amazonIndex')),
      },
    ],
  },
];
