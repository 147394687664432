
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const approvalRouteConfig = [
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/adminApproval',
        component: React.lazy(() => import('./adminApproval')),
      },
    ],
  }
];
