/************
 * Developed by : Shiva Software Solutions
 * Date : 15-03-2023
 * Descriptions : vendor credentials api calling
 ************/ import {
  SHOW_MESSAGE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_VENDOR_DATA,
  GET_VENDORUPDATE_DATA,
  GET_VENDOR_USER,
  NEW_VENDOR_NOTIFI,
  GET_VENDORCREDENTIAL_DATA,
  GET_VENDORCREDENTIAL_DATA_COUNT,
} from '../../shared/constants/ActionTypes';
import {useSelector} from 'react-redux';
import IntlMessages from '../../@crema/utility/IntlMessages';
import React from 'react';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'userManagement.js';
//Get vendor list data
export const onGetVendorCredentialdata = ({userId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/getVendorListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, jwtToken, userCode};
              jwtAxios
                .post('v1/vendor/getVendorList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_VENDOR_DATA,
                      payload: data.data.body,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetvendordata', '0', err),
      );
    }
  };
};

// Reset User Password
export const vendorresetPassword = ({credentialId, userCode, userPaswd}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {credentialId, userCode, userPaswd};

    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/vendor/vendorresetUserPaswdJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token
            try {
              if (data.data.hasOwnProperty.call(data.data, 'message')) {
                dispatch({type: FETCH_ERROR, payload: data.data.message});

                return;
              }
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userCode,
                credentialId,
                userPaswd,

                jwtToken,
              };
              jwtAxios
                .post('v1/vendor/vendorresetuserpaswd', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch({
                      type: NEW_VENDOR_NOTIFI,
                      payload: getuserdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onresetvendorPassword',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onresetvendorPassword',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onresetvendorPassword',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onresetvendorPassword',
          '0',
          err,
        ),
      );
    }
  };
};

//Add vendor api call
export const Addvendor = (value, clientwise = false) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/Addvendorcredentialjwt', value)
        .then((data) => {
          if (data.status === 200 && data.data && data.data.message) {
            dispatch({
              type: FETCH_ERROR,
              payload: data.data.message.split(':')[1],
            });
          } else {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/vendor/Addvendorcredential', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: GET_VENDORUPDATE_DATA,
                      payload: 'true',
                    });
                    if (clientwise) {
                      dispatch(
                        GetVendorCredentialdata({
                          userId: value.userId,
                          userCode: value.userCode,
                          clientId: Number(value.clientId),
                          offset: 0,
                          limit: 10,
                        }),
                      );
                    } else {
                      dispatch(
                        onGetVendorCredentialdata({
                          userId: value.userId,
                          userCode: value.userCode,
                        }),
                      );
                      dispatch(
                        GetVendorCredentialdata({
                          userId: value.userId,
                          userCode: value.userCode,
                          offset: 0,
                          limit: 10,
                        }),
                      );
                    }
                    dispatch({
                      type: NEW_VENDOR_NOTIFI,
                      payload: data.data.message,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: data?.data?.message.includes('Error')
                        ? data?.data?.message.split('Error:')[1]
                        : '' || '',
                    });
                    dispatch({
                      type: GET_VENDORUPDATE_DATA,
                      payload: 'false',
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'addvendor',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'addvendor',
                  '0',
                  err,
                ),
              );
            }
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(streamname, filename, 'Addvendor', '0', error),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'Addvendor', '0', err),
      );
    }
  };
};

//Get vendorcredential list data
export const GetVendorCredentialdata = ({
  userId,
  userCode,
  clientId = '',
  limit,
  offset,
  searchText,
}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, userCode, clientId, limit, offset, searchText};
    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/getVendorCredentialListjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call User list data - Get user list data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {
                userId,
                jwtToken,
                userCode,
                clientId,
                limit,
                offset,
                searchText,
              };
              jwtAxios
                .post('v1/vendor/getVendorCredentialList', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: GET_VENDORCREDENTIAL_DATA,
                      payload: data?.data?.body?.list
                        ? data?.data?.body?.list
                        : [],
                    });
                    dispatch({
                      type: GET_VENDORCREDENTIAL_DATA_COUNT,
                      payload: data?.data?.body?.listCount
                        ? data?.data?.body?.listCount
                        : 0,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetvendorcredentialsdata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetvendorcredentialsdata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetvendorcredentialsdata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetvendorcredentialsdata',
          '0',
          err,
        ),
      );
    }
  };
};

//update vendor credential api call
// update api
export const Updatevendor = (value, clientwise = false) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/vendor/vendorlistupdatejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/vendor/vendorlistupdate', value)
              .then(async (data) => {
                if (data.status === 200) {
                  if (!data.data.message.includes('Error')) {
                    dispatch({
                      type: GET_VENDORUPDATE_DATA,
                      payload: 'true',
                    });
                    if (clientwise) {
                      dispatch(
                        GetVendorCredentialdata({
                          userId: value.userId,
                          userCode: value.userCode,
                          clientId: Number(value.clientId),
                          offset: 0,
                          limit: 10,
                        }),
                      );
                    } else {
                      dispatch(
                        GetVendorCredentialdata({
                          userId: '',
                          userCode: value.userCode,
                          offset: 0,
                          limit: 10,
                        }),
                      );
                      setTimeout(() => {
                        dispatch(
                          onGetVendorCredentialdata({
                            userId: '',
                            userCode: value.userCode,
                          }),
                        );
                      }, 1000);
                    }
                    dispatch({
                      type: NEW_VENDOR_NOTIFI,
                      payload: data.data.message,
                    });
                  } else {
                    var userupadte = data.data.message.split(':')[1];

                    dispatch({type: FETCH_ERROR, payload: userupadte});
                    dispatch({
                      type: GET_VENDORUPDATE_DATA,
                      payload: 'false',
                    });
                  }
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdatevendorUser',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdateGudMedUser',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdateGudMedUser',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdateGudMedUser',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete vendor data
export const onDeleteVendor = ({credentialId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {credentialId, userCode};
    //Call Delete user - get Token
    try {
      jwtAxios
        .post('v1/vendor/deleteVendorjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Delete user data - Delete userdata using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {credentialId, jwtToken, userCode};
              jwtAxios
                .post('v1/vendor/deleteVendor', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getuserdata = data.data.message;
                    dispatch(
                      GetVendorCredentialdata({
                        credentialId: credentialId,
                        userCode: userCode,
                        offset: 0,
                        limit: 10,
                      }),
                    );
                    setTimeout(() => {
                      if (!data.data.message.includes('Error')) {
                        dispatch({
                          type: NEW_VENDOR_NOTIFI,
                          payload: getuserdata,
                        });
                      } else {
                        var userdeletemsg = data.data.message.split(':')[1];
                        dispatch({
                          type: FETCH_ERROR,
                          payload: userdeletemsg,
                        });
                      }
                    }, 2000);
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'deletevendordata',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'deletevendordata',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'deletevendordata',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'deletevendordata',
          '0',
          err,
        ),
      );
    }
  };
};
