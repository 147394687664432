import React, {useState, useEffect} from 'react';
import './Notification.css'; // Define your animation styles here
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const Notification = ({message}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show notification when message is set
    if (message) {
      setIsVisible(true);
      // Automatically hide notification after some time (e.g., 5 seconds)
      setTimeout(() => {
        setIsVisible(false);
      }, 5000); // Adjust the timeout as needed
    }
  }, [message]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div
      className={`notification ${isVisible ? 'show' : ''}`}
      onClick={handleClose}>
      <div className='notifyHead'>
        <NotificationsActiveIcon className='notifyIcon' /> High Priority Alert!
      </div>
      <div className='notification-content'>
        {message.map((msg, index) => (
          <span key={index}>{msg}</span>
        ))}
        {/* <button onClick={handleClose}>&times;</button> */}
      </div>
    </div>
  );
};

export default Notification;
