/************
 * Developed by : Shiva Software Solutions
 * Date : 26-12-2023
 * Descriptions : Route for digitization dashboard page
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const digitizationDashBoardConfigs = [
  //Redirect to dashboard page
  {
    auth: authRole.user,
    routes: [
      {
        path: '/digitizationdashboard',
        component: React.lazy(() =>
          import('./DashboardPage/digitizationIndex'),
        ),
      },
    ],
  },
];
