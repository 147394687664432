/************
 * Author : ArunaDevi T
 * Developed by : Shiva Software Solutions
 * Date : 21-06-2021
 * Descriptions : Main page of application
 * Dependicies : To use JWT Authendication token
 * Modified Date :21-06-2021
 * Modified Author Name :ArunaDevi T
 * Modified Fuction :Application Right click prevent
 ************/
import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';
import {useEffect} from 'react';
import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
//import {Beforeunload} from 'react-beforeunload';
const store = configureStore();

//Prevent Right Click
const preventRightClick = () => {
  // document.addEventListener('contextmenu', (event) => event.preventDefault());
};

window.addEventListener('beforeunload', (ev) => {
  ev.preventDefault();
  return (ev.returnValue = 'Are you sure you want to close?');
});
const App = () => (
  <ContextProvider onClick={preventRightClick()}>
    <ContextProvider>
      <Provider store={store}>
        <CremaThemeProvider>
          <CremaStyleProvider>
            <LocaleProvider>
              <ConnectedRouter history={history}>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                </AuthRoutes>
              </ConnectedRouter>
            </LocaleProvider>
          </CremaStyleProvider>
        </CremaThemeProvider>
      </Provider>
    </ContextProvider>
  </ContextProvider>
);

export default App;
