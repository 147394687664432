/************
 * Developed by : Shiva Software Solutions
 * Date : 29-07-2021
 * Descriptions : Call api response using reducers for doctor master
 ************/
import {
  GET_DOCTORMASTER_DATA,
  UPDATE_DOCTORMASTER_DATA,
  GET_PENDING_DOCTORMASTER_DATA,
  GET_INACTIVE_DOCTORMASTER_DATA,
  GET_PRESCRIPTION_DOCTORMASTER,
} from '../../shared/constants/ActionTypes';
//Inital state
const initialState = {
  doctors: [],
  doctordatas: '',
  inactivedoctors: [],
  pendingdoctors: [],
  prescriptiondoctors: [],
};
//Doctor List
const doctorMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    //doctor master list
    case GET_DOCTORMASTER_DATA:
      return {
        ...state,
        doctors: action.payload,
      };
    //Get pending doctor master data
    case GET_PENDING_DOCTORMASTER_DATA:
      return {
        ...state,
        pendingdoctors: action.payload,
      };
    //Get inactive doctor master data
    case GET_INACTIVE_DOCTORMASTER_DATA:
      return {
        ...state,
        inactivedoctors: action.payload,
      };
    //Update doctor master data
    case UPDATE_DOCTORMASTER_DATA:
      return {
        ...state,
        doctordatas: action.payload,
      };
    //Get prescription doctor master
    case GET_PRESCRIPTION_DOCTORMASTER:
      return {
        ...state,
        prescriptiondoctors: action.payload,
      };
    default:
      return state;
  }
};
export default doctorMasterReducer;
