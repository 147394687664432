/************
 * Developed by : Shiva Software Solutions
 * Date : 15-05-2024
 * Descriptions : IPD api calling
 ************/
import {
  IPD_ADD_MEDICINE,
  FETCH_SUCCESS,
  FETCH_ERROR,
  FETCH_START,
  APPROVE_IPDMEDICINE_MSG,
  UPDATE_DOCTOR_SUCCESS_MSG,
} from '../../shared/constants/ActionTypes';

import React from 'react';
import Api from '../../@crema/services/ApiConfig';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
// const variables
import {
  sendReview,
  backReview,
  userRoleMaster,
} from '../../shared/constants/AppConst';
var streamname = 'Redux_Actions';
var filename = 'ipdServices.js';

//Add medicine details to marg data
export const addIpdMedicineData = (value) => {
  return (dispatch) => {
    try {
      jwtAxios
        .post('v1/ipdPres/insertAddIPDMedicinejwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call inprogresetUserPaswds list data - Get pending list data using token
            try {
              value['jwtToken'] = data.data.body.token;
              jwtAxios
                .post('v1/ipdPres/insertAddIPDMedicine', value)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: IPD_ADD_MEDICINE,
                      payload: 'Yes',
                    });
                  } else {
                    dispatch({
                      type: IPD_ADD_MEDICINE,
                      payload: 'No',
                    });
                  }
                })
                .catch((error) => {
                  dispatch({
                    type: IPD_ADD_MEDICINE,
                    payload: 'No',
                  });
                  // dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'addIpdMedicineData',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'addIpdMedicineData',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'addIpdMedicineData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'addIpdMedicineData',
          '0',
          err,
        ),
      );
    }
  };
};

export const onApproveIPDMedicine = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/approveIPDMedicineDatajwt', value)
        .then((data) => {
          if (data.status === 200) {
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/ipdPres/approveIPDMedicineData', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});

                    dispatch({
                      type: APPROVE_IPDMEDICINE_MSG,
                      payload: 'Yes',
                    });
                  } else {
                    dispatch({
                      type: APPROVE_IPDMEDICINE_MSG,
                      payload: 'No',
                    });
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({
                    type: APPROVE_IPDMEDICINE_MSG,
                    payload: '',
                  });
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onApproveIPDMedicine',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch({
                type: APPROVE_IPDMEDICINE_MSG,
                payload: '',
              });
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onApproveIPDMedicine',
                  '0',
                  err,
                ),
              );
            }
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onApproveIPDMedicine',
          '0',
          err,
        ),
      );
    }
  };
};

// updateDoctorMasterData
export const addNewClientDoctor = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/addClientDoctorJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/ipdPres/addClientDoctor', value)
              .then((data) => {
                let responseMsg = data?.data?.message;
                dispatch({type: FETCH_SUCCESS});
                if (data.status === 200) {
                  if (responseMsg && responseMsg?.includes('Error')) {
                    dispatch({
                      type: UPDATE_DOCTOR_SUCCESS_MSG,
                      payload: data?.data?.message,
                    });
                  } else {
                    dispatch({
                      type: UPDATE_DOCTOR_SUCCESS_MSG,
                      payload: data?.data?.body,
                    });
                  }
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'addNewClientDoctor',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'addNewClientDoctor',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_DOCTOR_SUCCESS_MSG,
            payload: 'failure',
          });
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'addNewClientDoctor',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: UPDATE_DOCTOR_SUCCESS_MSG,
        payload: 'failure',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'addNewClientDoctor',
          '0',
          err,
        ),
      );
    }
  };
};

export const resetDoctorpassword = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/ipdPres/resetDocpasswordjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/ipdPres/resetDocpassword', value)
              .then((data) => {
                let responseMsg = data?.data?.message;
                console.log(responseMsg, 'responseMsg');
                dispatch({type: FETCH_SUCCESS});
                if (data.status === 200) {
                  dispatch({
                    type: UPDATE_DOCTOR_SUCCESS_MSG,
                    payload: data?.data?.body,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'addNewClientDoctor',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'addNewClientDoctor',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_DOCTOR_SUCCESS_MSG,
            payload: 'failure',
          });
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'addNewClientDoctor',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch({
        type: UPDATE_DOCTOR_SUCCESS_MSG,
        payload: 'failure',
      });
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'addNewClientDoctor',
          '0',
          err,
        ),
      );
    }
  };
};
