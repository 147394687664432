/************
 * Developed by : Shiva Software Solutions
 * Date : 21-10-2022
 * Descriptions : Route page for erx upload page
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const appointmentBookingConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/appointmentBooking',
        component: React.lazy(() => import('./appointmentBooking')),
      },
    ],
  },
];
