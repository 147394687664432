/************
 * Developed by : Shiva Software Solutions
 * Date : 11-05-2023
 * Descriptions : Reports page api calling
 ************/
import React from 'react';
import Api from '../../@crema/services/ApiConfig';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
// const variables
import {
  sendReview,
  backReview,
  userRoleMaster,
} from '../../shared/constants/AppConst';
import {DataBrew} from 'aws-sdk';
import {
  FETCH_START,
  FETCH_ERROR,
  DIGI_REPORT_RESULT_SUCCESS,
  DIGI_AMAZON_REPORT_LIST,
  FETCH_SUCCESS,
  ERX_DATA_RHTML,
  CLIENT_LIST_REPORT,
  ERX_DATA_RHTML_NO_DATA,
  DOCTOR_REPORT,
  PATIENT_REPORT,
  SEARCH_DISEASE_DATA,
  SEARCH_DIAGONSIS_DATA,
  SEARCH_SPECIALIZATION_DATA,
  SEARCH_SYMPTOMS_DATA,
  TAG_CATEGORY_REPORT,
  SEARCH_MEDICINE_DATA,
  DIGI_PREVIEW_DATA,
  REPORT_RESULT,
  EXCEL_REPORT_LIST,
  EXCEL_AMAZON_REPORT_LIST,
  DIGI_REPORT_COUNT,
  DIGI_REPORT_RESULT,
  REPORT_COUNT,
  ALLERGY_REPORT,
  PROCEDURE_REPORT,
  REPORT_RESULT_SUCCESS,
  REJECT_REASON_REPORT,
} from '../../shared/constants/ActionTypes';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'reports.js';

export const eRxReportMasterList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/eRxReportMasterListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/eRxReportMasterList', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: CLIENT_LIST_REPORT,
                    payload: data.data.body.clientlist,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'ongetclientlist',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'ongetclientlist',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetclientlist',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'ongetclientlist', '0', err),
      );
    }
  };
};

export const eRxReportAutocompleteList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/eRxReportAutocompleteListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/eRxReportAutocompleteList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let tag = data.data.body.tag;
                  let array = [];
                  array.push(data.data.body.list ? data.data.body.list : []);
                  if (tag == 'diagonsis') {
                    dispatch({
                      type: SEARCH_DIAGONSIS_DATA,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'disease') {
                    dispatch({
                      type: SEARCH_DISEASE_DATA,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'investigation') {
                    dispatch({
                      type: TAG_CATEGORY_REPORT,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'specialization') {
                    dispatch({
                      type: SEARCH_SPECIALIZATION_DATA,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'symptoms') {
                    dispatch({
                      type: SEARCH_SYMPTOMS_DATA,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'medicines') {
                    dispatch({
                      type: SEARCH_MEDICINE_DATA,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'doctor') {
                    dispatch({
                      type: DOCTOR_REPORT,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'allergy') {
                    dispatch({
                      type: ALLERGY_REPORT,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'procedure') {
                    dispatch({
                      type: PROCEDURE_REPORT,
                      payload: data.data.body.list,
                    });
                  }
                } else {
                  dispatch({
                    type: SEARCH_DISEASE_DATA,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeSearchDiseaseData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeSearchDiseaseData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeSearchDiseaseData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeSearchDiseaseData',
          '0',
          err,
        ),
      );
    }
  };
};

//Generate Html
export const GenerateERXHTML = (body) => {
  return (dispatch) => {
    dispatch({
      type: ERX_DATA_RHTML,
      payload: [],
    });
    dispatch({type: FETCH_START});
    //Call symptom list Token  - Get Token
    try {
      //Call symptom User list data - Get symptom list data using token
      try {
        jwtAxios
          .post('v1/pateRxMgmt/generateHTML', body)
          .then((data) => {
            dispatch({type: FETCH_SUCCESS});
            if (data && data.status && data.status === 200) {
              if (data.data.body) {
                dispatch({
                  type: ERX_DATA_RHTML_NO_DATA,
                  payload: '',
                });
                dispatch({
                  type: ERX_DATA_RHTML,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: ERX_DATA_RHTML_NO_DATA,
                  payload: 'No record found',
                });
              }
            }
          })
          .catch((error) => {
            dispatch({
              type: ERX_DATA_RHTML_NO_DATA,
              payload: 'No record found',
            });
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'GetCovidVitalsListData',
                '0',
                error,
              ),
            );
          });
      } catch (err) {
        dispatch({
          type: ERX_DATA_RHTML_NO_DATA,
          payload: 'No record found',
        });
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'GetCovidVitalsListData',
            '0',
            err,
          ),
        );
      }
    } catch (err) {
      dispatch({
        type: ERX_DATA_RHTML_NO_DATA,
        payload: 'No record found',
      });
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'GetCovidVitalsListData',
          '0',
          err,
        ),
      );
    }
  };
};

//amazon reports

export const eRxAmazonReportAutocompleteList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/eRxAmazonReportAutocompleteListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/eRxAmazonReportAutocompleteList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  let tag = data.data.body.tag;
                  let array = [];
                  array.push(data.data.body.list ? data.data.body.list : []);
                  if (tag == 'doctor') {
                    dispatch({
                      type: DOCTOR_REPORT,
                      payload: data.data.body.list,
                    });
                  } else if (tag == 'patient') {
                    dispatch({
                      type: PATIENT_REPORT,
                      payload: data.data.body.list,
                    });
                  }
                } else {
                  dispatch({
                    type: DOCTOR_REPORT,
                    payload: [],
                  });
                  dispatch({
                    type: PATIENT_REPORT,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'eRxAmazonReportAutocompleteList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'eRxAmazonReportAutocompleteList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'eRxAmazonReportAutocompleteList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'eRxAmazonReportAutocompleteList',
          '0',
          err,
        ),
      );
    }
  };
};

export const ongetreportlist = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios.post('v1/reportDtls/onGetReportListjwt', value).then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          jwtAxios.post('v1/reportDtls/onGetReportList', value).then((data) => {
            try {
              dispatch({type: FETCH_SUCCESS});
              if (data?.data?.status === 200) {
                if (data?.data?.body?.result) {
                  dispatch({
                    type: REPORT_RESULT,
                    payload: data.data.body.result,
                  });
                  dispatch({
                    type: REPORT_RESULT_SUCCESS,
                    payload: 'Success',
                  });
                  dispatch({
                    type: REPORT_COUNT,
                    payload: data.data.body.totalcount.totalcount,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: 'Something went wrong',
                  });
                }
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: 'Something went wrong',
                });
              }
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'ongetreportlist',
                  '0',
                  err,
                ),
              );
            }
          });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetreportlist',
              '0',
              err,
            ),
          );
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'ongetreportlist', '0', err),
      );
    }
  };
};

export const onGetExportReportList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios.post('v1/reportDtls/onGetReportListjwt', value).then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          jwtAxios.post('v1/reportDtls/onGetReportList', value).then((data) => {
            try {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: EXCEL_REPORT_LIST,
                  payload: data.data.body.result,
                });
              }
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetExportReportList',
                  '0',
                  err,
                ),
              );
            }
          });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetExportReportList',
              '0',
              err,
            ),
          );
        }
      });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'ongetreportlist', '0', err),
      );
    }
  };
};

export const eRxAmazonRejectionMasterList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/eRxAmazonRejectionMasterListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/eRxAmazonRejectionMasterList', value)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: REJECT_REASON_REPORT,
                    payload: data.data.body.reasonList,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'eRxAmazonRejectionMasterList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'eRxAmazonRejectionMasterList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'eRxAmazonRejectionMasterList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'eRxAmazonRejectionMasterList',
          '0',
          err,
        ),
      );
    }
  };
};

export const ongetamazonreportlist = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/onGetAmazonReportListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/onGetAmazonReportList', value)
              .then((data) => {
                try {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: REPORT_RESULT,
                      payload: data.data.body.result,
                    });
                    dispatch({
                      type: REPORT_RESULT_SUCCESS,
                      payload: 'Success',
                    });
                    dispatch({
                      type: REPORT_COUNT,
                      payload: data.data.body.totalcount.totalcount,
                    });
                  }
                } catch (err) {
                  dispatch(fetchError(err.response.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'ongetamazonreportlist',
                      '0',
                      err,
                    ),
                  );
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'ongetamazonreportlist',
                '0',
                err,
              ),
            );
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetamazonreportlist',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetExportAmazonReportList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/teleConsultant/onGetAmazonReportListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/teleConsultant/onGetAmazonReportList', value)
              .then((data) => {
                try {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    dispatch({
                      type: EXCEL_AMAZON_REPORT_LIST,
                      payload: data.data.body.result,
                    });
                  }
                } catch (err) {
                  dispatch(fetchError(err.response.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetAmazonReportList',
                      '0',
                      err,
                    ),
                  );
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetAmazonReportListjwt',
                '0',
                err,
              ),
            );
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetExportAmazonReportList',
          '0',
          err,
        ),
      );
    }
  };
};

export const ongetdigitizationreportlist = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/onGetDigitizationReportListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/onGetDigitizationReportList', value)
              .then((data) => {
                try {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    if (value && value?.process == 'excel') {
                      dispatch({
                        type: DIGI_AMAZON_REPORT_LIST,
                        payload: data.data.body.result,
                      });
                    } else {
                      dispatch({
                        type: DIGI_REPORT_RESULT,
                        payload: data.data.body.result,
                      });
                      dispatch({
                        type: DIGI_REPORT_RESULT_SUCCESS,
                        payload: 'Success',
                      });
                      dispatch({
                        type: DIGI_REPORT_COUNT,
                        payload: data.data.body.totalcount?.count,
                      });
                    }
                  }
                } catch (err) {
                  dispatch(fetchError(err.response.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'ongetdigitizationreportlist',
                      '0',
                      err,
                    ),
                  );
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'ongetdigitizationreportlist',
                '0',
                err,
              ),
            );
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetdigitizationreportlist',
          '0',
          err,
        ),
      );
    }
  };
};

export const ongetDigitizationreportdetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/digitization/getDigiPreviewDetJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/digitization/getDigiPreviewDet', value)
              .then((data) => {
                try {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    console.log(data?.data?.body, 'result');
                    dispatch({
                      type: DIGI_PREVIEW_DATA,
                      payload: data?.data?.body,
                    });
                  }
                } catch (err) {
                  dispatch(fetchError(err.response.message));
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'ongetDigitizationreportdetails',
                      '0',
                      err,
                    ),
                  );
                }
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'ongetDigitizationreportdetails',
                '0',
                err,
              ),
            );
          }
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetDigitizationreportdetails',
          '0',
          err,
        ),
      );
    }
  };
};
