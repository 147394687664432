/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route page for signup
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';

export const authRouteConfig = [
  {
    //Redirect to signin page
    routes: [
      {
        path: '/signin',
        component: React.lazy(() => import('./Signin/index')),
      },
    ],
  },
  //Redirect to forgot password page
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(() => import('./ForgetPassword')),
      },
    ],
  },
  //Redirect to error page
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-401',
        component: React.lazy(() => import('../errorPages/Error401/index')),
      },
    ],
  },
];
